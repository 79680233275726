import FormSelect from "./FormSelect";

const FieldSelectSatelliteDeviceType = (props) => {
    return (
        <FormSelect {...props} name="satelliteDeviceType" placeholder="Satellite Device Type" label="Satellite Device Type" options={[
            { value: '', label: "Device Type" },
            { value: 'ZOLEO', label: "Zoleo" },
            { value: 'SPOT', label: "Spot" },
            { value: 'GARMIN', label: "Garmin" },
        ]} />
    )
}

export default FieldSelectSatelliteDeviceType;