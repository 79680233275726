import FormSelect from "./FormSelect";

const FormSelectShiftType = ({ name = "shiftType", placeholder = "Select Shift Type", label = "Shift Type", ...rest }) => {
    return (
        <FormSelect {...rest} name={name} placeholder={placeholder} label={label} options={[
            { value: '', label: "Select Shift Type" },
            { value: 'RECURRING', label: "Recurring" },
            { value: 'INDIVIDUAL', label: "Individual Shift" },
        ]} />
    )
}

export default FormSelectShiftType;