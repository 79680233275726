import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { searchUsers, selectUser } from "../common/slices/user"
import { AdminButton } from "./Buttons"
import FormTextInput from "./FormTextInput"

const SearchContainer = styled.form`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

export default () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const onSubmit = async (values) => {
        console.log(values, 'here');
        await dispatch(searchUsers(values.search, user.companyId, user.role));
    }

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            search: ""
        }
    })
    const fieldProps = { control, errors }

    return (
        <SearchContainer onSubmit={handleSubmit(onSubmit)}>
            <FormTextInput
                {...fieldProps}
                name="search"
                placeholder="Search name, email, or phone number"
                style={{ padding: '0.75rem', background: 'rgba(16, 18, 36, 1)', color: 'white', border: 'none' }}
            />
            <AdminButton onClick={handleSubmit(onSubmit)}>Search</AdminButton>
            <AdminButton onClick={() => reset()}>Reset</AdminButton>
        </SearchContainer>
    )
}