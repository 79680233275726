import { API } from "aws-amplify";
import { customDeleteUser } from "../graphql/mutations";

export default async ({ email }) => {
    try {
        await API.graphql({
            query: customDeleteUser,
            variables: {
                input: {
                    email,
                },
            },
        });
    } catch (ex) {
        console.error("Failed to delete user", ex);
    }
};
