import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import service from "../service";

import { useForm } from "react-hook-form";
import { selectActiveUser } from "../common/slices/user";
import theme from '../common/theme';
import { ButtonBase, RedButton } from "./Buttons";
import FormCheckbox from "./FormCheckbox";
import FormTextInput from "./FormTextInput";
import Modal from './Modal';
import { H1 } from "./Typography";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`

const Footer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid ${theme.palette.border}
`

const ModalSetPassword = ({ show, onClose }) => {
    const activeUser = useSelector(selectActiveUser);

    const onSaveClick = async (values) => {
        const success = await service.setUserPassword(values);
        if (success) {
            onClose();
        } else {
            setError("password", { message: "Invalid password" })
        }
    }

    const {
        control,
        setError,
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
    } = useForm({
        defaultValues: {
            username: activeUser?.email,
        }
    })
    const fieldProps = { control, errors };

    return (
        <Modal
            show={show}
            onClose={onClose}
            size='sm'
        >
            <Container>
                <Header>
                    <H1>Set User Password</H1>
                    <ButtonBase onClick={onClose}><FontAwesomeIcon icon={faTimesCircle} size={'xl'} /></ButtonBase>
                </Header>
                <form onSubmit={handleSubmit}>
                    <FormTextInput {...fieldProps} name="password" placeholder="New Password" label="New Password" type="password" rules={{ required: true }} />
                    <FormCheckbox {...fieldProps} name="permanent" placeholder="Permanently set password?" type="checkbox" />
                    <p>If you do not permanently set the password, the user will be emailed this password and requested that they change it on their next login.</p>
                </form>
                <Footer>
                    <RedButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid}>Set Password</RedButton>
                    <ButtonBase onClick={onClose}>Cancel</ButtonBase>
                </Footer>
            </Container>
        </Modal>
    )
}

export default ModalSetPassword