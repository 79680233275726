import styled from "styled-components";
import theme from "../common/theme";
import Portal from "./Portal";

const SideBarContainer = styled.div`
    border-left: 1px solid ${theme.palette.border};
    transition: all 0.25s;
    overflow: hidden;

    @media (max-width: 768px) {
        display: none;
    }
`;

const SideBarBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const SideBar = ({ show, children }) => {
    return (
        <Portal
            node={document.getElementsByTagName('body')[0]}
        >
            <SideBarContainer style={{
                width: show ? '350px' : '0',
                // display: show ? undefined : 'none',
            }}>
                {
                    show &&
                    <SideBarBody>
                        {children}
                    </SideBarBody>
                }
            </SideBarContainer>
        </Portal>
    )
}

export default SideBar