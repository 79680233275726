import { Marker } from "@react-google-maps/api";
import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";

import isNotification from "../common/utils/isNotification";

const createKey = (checkIn) => {
    return checkIn.userId;
}


const CheckInMarker = ({ checkIn, onClick, ...props }) => {
    const [uri, setUri] = useState("");


    useEffect(() => {
        const getUri = async (k) => {
            const resp = await Storage.get(k);
            setUri(resp);
        };

        getUri(`${checkIn.status}.png`);
    }, [checkIn.status]);

    return (
        <Marker
            {...props}
            key={createKey(checkIn)}
            position={checkIn.location}
            onClick={() => onClick && onClick(checkIn)}
            label={{
                text: `${checkIn?.user?.firstName} ${checkIn?.user?.lastName}`,
                className: isNotification(checkIn?.status) ? "map-notification" : "map-label",
            }}
            icon={{
                url: uri,
                scaledSize: new window.google.maps.Size(35, 35),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 35),
                labelOrigin: new window.google.maps.Point(100, 17),
            }}
        />
    )
}

export default CheckInMarker;