import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Footer } from "./Layout";
import styled from "styled-components";
import theme from "../common/theme";
import logo from '../assets/logo.svg';
import Row from "./Row";
import { ButtonBase } from "./Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck, faCircleDot, faCircleH, faCircleUser, faMehBlank } from "@fortawesome/free-solid-svg-icons";
import { selectStatus } from "../common/slices/registration";
import { useSelector } from "react-redux";

const ScreenHeader = styled.div`
    padding: 1rem;
    border-bottom: 1px solid ${theme.palette.border};
    `

const Logo = styled.img`
    line-height: inherit;
`

const SideBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    border-right: 1px solid ${theme.palette.border};
    padding: 1rem;
    align-items: stretch;
    gap: 1.5rem;
`

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 800px;
`

const SideBarButton = ({ children, done = false, active = false, path, ...rest }) => {
    const nav = useNavigate();
    return (
        <ButtonBase style={{ color: active ? theme.palette.lightBlue : 'white', display: 'flex', }} onClick={() => nav(path)} {...rest} >
            {children}
            <FontAwesomeIcon icon={done ? faCircleCheck : faCircle} style={{ marginLeft: 'auto', color: (done || active) ? theme.palette.lightBlue : 'white' }} />
        </ButtonBase>
    )
}

const SideBar = () => {
    const status = useSelector(selectStatus);
    const location = useLocation();

    const termsDone = status?.termsOfService?.done;
    const accountOwnerDone = status?.accountOwner?.done;
    const companyDone = status?.company?.done;
    const erpGroupsDone = status?.erpGroups?.done;
    const workersDone = status?.workers?.done;
    const paymentDone = status?.payment?.done;

    return (
        <SideBarContainer>
            <SideBarButton done={termsDone} active={location.pathname === '/auth/registration/'} path="./">Terms of Service</SideBarButton>
            <SideBarButton done={accountOwnerDone} active={location.pathname.indexOf("/account-owner") > -1} path="./account-owner" disabled={!termsDone}>Account Owner</SideBarButton>
            <SideBarButton done={companyDone} active={location.pathname.indexOf("/company") > -1} path="./company" disabled={!accountOwnerDone}>Company</SideBarButton>
            <SideBarButton done={erpGroupsDone} active={location.pathname.indexOf("/erp-groups") > -1} path="./erp-groups" disabled={!companyDone}>ERP Groups</SideBarButton>
            <SideBarButton done={workersDone} active={location.pathname.indexOf("/workers") > -1} path="./workers" disabled={!erpGroupsDone}>Workers</SideBarButton>
            <SideBarButton done={paymentDone} active={location.pathname.indexOf("/payment") > -1} path="./payment" disabled={!workersDone}>Payment</SideBarButton>
        </SideBarContainer>
    )
}

export default () => (
    <>
        <ScreenHeader>
            <Logo src={logo} />
        </ScreenHeader>
        <Row style={{ flex: 1, alignItems: 'stretch', gap: 0 }}>
            <SideBar />
            <Outlet />
        </Row>
        <Footer />
    </>
)