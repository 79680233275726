import { API } from "aws-amplify";
import { getUser } from "../graphql/queries";

export default async (email) => {
    try {
        const {
            data: { getUser: user },
        } = await API.graphql({
            query: getUser,
            variables: {
                email,
            },
        });
        return user;
    } catch (e) {
        console.log("failed to fetch user", e);
        return false;
    }
};
