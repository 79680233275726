import { API } from "aws-amplify";
import { createCheckIn } from "../graphql/mutations";

export default async ({ email, location, status, companyId, battery }) => {
    try {
        const {
            data: { createCheckIn: newCheckIn },
        } = await API.graphql({
            query: createCheckIn,
            variables: {
                input: {
                    userId: email,
                    timestamp: new Date(),
                    status,
                    location,
                    companyId,
                    battery
                },
            },
        });
        return newCheckIn;
    } catch (ex) {
        console.log("failed to insert check in", ex);
    }
};
