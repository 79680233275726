import FormSelect from "./FormSelect";

const FieldSelectProtocol = (props) => {
    return (
        <FormSelect {...props} name="checkInProtocol" placeholder="Check In Protocol" label="Check In Protocol" options={[
            { value: "", label: "Select Check In Protocol" },
            { value: 'THIRTY', label: "30 Minutes" },
            { value: 'SIXTY', label: "60 Minutes" },
            { value: 'NINETY', label: "90 Minutes" },
            { value: 'ONETWENTY', label: "2 Hours" },
            { value: 'TWOFORTY', label: "4 Hours" },
        ]} />


    )
}

export default FieldSelectProtocol;