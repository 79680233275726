/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "beSafeRest",
            "endpoint": "https://n5394nyzu2.execute-api.ca-central-1.amazonaws.com/pre",
            "region": "ca-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://52namp6pnrbkvdl6p6bvxj6ewa.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-pzyr2tc7izeflbw5qd4d67w3z4",
    "aws_cognito_identity_pool_id": "ca-central-1:9e1b435c-7062-4ab7-9d75-c38dd682411d",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_F7yo4MNaH",
    "aws_user_pools_web_client_id": "4em49jta6da2o5576lci5v1sug",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "besafeavatars170609-pre",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
