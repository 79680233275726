import { useFormContext } from 'react-hook-form';
import FormSelect from './FormMultiSelect';
import FormMultiSelect from './FormMultiSelect';

export default ({ ...rest }) => {
    return (
        <FormMultiSelect {...rest} options={[
            { value: "SMS", label: "sms" },
            { value: "EMAIL", label: "email" },
        ]} />
    );
};
