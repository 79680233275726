import moment from 'moment';

const dayMap = {
    0: 'SUNDAY',
    1: 'MONDAY',
    2: 'TUESDAY',
    3: 'WEDNESDAY',
    4: 'THURSDAY',
    5: 'FRIDAY',
    6: 'SATURDAY',
}

export default (rtnVal, schedule) => {
    const individualEvents = schedule.individualShifts?.items?.map((s, i) => {
        const startDate = moment(s.startTime).set('dayOfYear', moment(s.shiftDate, 'YYYY-MM-DD').dayOfYear()).toDate();
        const endDate = moment(s.endTime).set('dayOfYear', moment(s.shiftDate, 'YYYY-MM-DD').dayOfYear()).toDate();

        return {
            ...s,
            title: s.name || 'Individual',
            user: `${schedule.user?.firstName} ${schedule.user?.lastName}`,
            role: schedule.user?.role,
            type: s.__typename,
            startTime: moment(s.startTime).toDate(),
            endTime: moment(s.endTime).toDate(),
            start: startDate,
            end: endDate,
        }
    }) || []
    const recurringEvents = schedule.recurringShiftPatterns?.items?.reduce((events, s, i) => {
        const startDate = moment(s.startTime);
        const endDate = moment(s.endTime);
        let newEvents = [];
        
        for (var m = moment(s.startDate, 'YYYY-MM-DD'); m.isSameOrBefore(moment(s.endDate, 'YYYY-MM-DD')); m.add(1, 'days')) {
            if (s.shiftPattern.indexOf(dayMap[m.day()]) > -1) {
                newEvents.push({
                    ...s,
                    title: s.name || 'Recurring',
                    user: `${schedule.user?.firstName} ${schedule.user?.lastName}`,
                    role: schedule.user?.role,
                    type: s.__typename,
                    startTime: startDate.toDate(),
                    endTime: endDate.toDate(),
                    start: startDate.clone().set('dayOfYear', m.dayOfYear()).toDate(),
                    end: endDate.clone().set('dayOfYear', m.dayOfYear()).toDate(),
                    __typename: s.__typename
                })
            }
        }

        return [...events, ...newEvents]

    }, []) || [];
    return [...rtnVal, ...individualEvents, ...recurringEvents];
} 