import { createSelector, createSlice } from "@reduxjs/toolkit";
import { roles } from "../constants";
import service from "../service";
import { reset } from "./globalActions";
import { set } from "lodash";
import { API } from "aws-amplify";
import { updateContacts } from "../graphql/mutations";
import * as queries from "../graphql/customQueries";
import QueryBuilder from "../service/QueryBuilder";

const listCompaniesQuery = new QueryBuilder(queries.listCompanies, "listCompanies", 10)

const initialState = {
    loading: false,
    active: null,
    list: [],
    listPageTokens: [],
};

const companySlice = createSlice({
    name: "company",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(reset, () => initialState);
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setActiveCompany: (state, action) => {
            let active = { ...action.payload };
            const updatedERP = active.erpAreas?.items?.map((erpArea) => {
                const updatedContacts = erpArea.emergencyContacts?.items?.reduce((contacts, contact) => {
                    let notifyBy = [];
                    if (contact.notifyBySMS) notifyBy.push({ value: "SMS", label: "sms" });
                    if (contact.notifyByEmail) notifyBy.push({ value: "EMAIL", label: "email" });
                    return [...contacts, { ...contact, notifyBy }];
                }, []);
                return { ...erpArea, emergencyContacts: { items: updatedContacts } };
            });
            active.erpAreas = { items: updatedERP };
            state.active = active;
        },
        setCompanyList: (state, action) => {
            state.list = action.payload;
        },
        onUpdateCompany: (state, action) => {
            let companyIndex = state.list.findIndex((c) => c.id === action.payload.id);
            if (companyIndex >= 0) {
                state.list[companyIndex] = action.payload;
            } else {
                state.list.push(action.payload);
            }
        },
        onDeleteCompany: (state, action) => {
            let companyIndex = state.list.findIndex((c) => c.id === action.payload.id);
            state.list = [...state.list.slice(0, companyIndex), ...state.list.slice(companyIndex + 1)];
        },
        onInsertCompany: (state, action) => {
            state.list.push(action.payload);
        },
    },
});

export const { setLoading, setCompanyList, setActiveCompany, onInsertCompany, onUpdateCompany, onDeleteCompany } = companySlice.actions;
export default companySlice.reducer;

export const selectState = (state) => state.company;
export const selectCompanyList = createSelector(selectState, (state) => state.list);
export const selectActiveCompany = createSelector(selectState, (state) => state.active);

export const listCompanies = (companyId, role, pageSize = 10) => async (dispatch) => {
    let companies = [];
    if (role === roles.OPERATOR) {
        listCompaniesQuery.pageSize = pageSize;
        listCompaniesQuery.setFilter({});
        companies = await listCompaniesQuery.execute();
        dispatch(setCompanyList(companies.items));
    } else if (companyId) {
        let company = await service.getCompany(companyId);
        companies = [company];
        dispatch(
            setCompanyList(companies)
        );
    } else {
        console.log("must specify a companyId if not superuser");
    }
    return companies;
};

export const nextPage = (companyId, role) => async (dispatch) => {
    let companies = [];
    if (role === roles.OPERATOR) {
        companies = await listCompaniesQuery.nextPage();
        dispatch(setCompanyList(companies.items));
    }
}

export const prevPage = (companyId, role) => async (dispatch) => {
    let companies = [];
    if (role === roles.OPERATOR) {
        companies = await listCompaniesQuery.prevPage();
        dispatch(setCompanyList(companies.items));
    }
}

export const searchCompanies = (search) => async (dispatch) => {
    let companies = [];
    listCompaniesQuery.setFilter({ or: [{ name: { contains: search } }] })
    listCompaniesQuery.pageSize = null;
    companies = await listCompaniesQuery.execute();
    dispatch(setCompanyList(companies.items));
}

export const insertCompany = (company) => async (dispatch) => {
    const insertedCompany = await service.insertCompany(company);
    dispatch(onInsertCompany(insertedCompany));
};

export const updateCompany = (company) => async (dispatch) => {
    const id = await service.updateCompany(company);
    const updatedCompany = await service.getCompany(id);
    dispatch(onUpdateCompany(updatedCompany));
};

export const deleteCompany = (company) => async (dispatch) => {
    const success = await service.deleteCompany(company);
    if (success) { dispatch(onDeleteCompany(company)) }
    return success;
};

export const deleteERP = (erpArea) => async (dispatch) => {
    await service.deleteERP(erpArea);
};

export const deleteContact = (contact) => async (dispatch) => {
    await service.deleteContact(contact);
};

export const updateERPContacts = (contacts) => async (dispatch) => {
    try {
        const response = await API.graphql({
            query: updateContacts,
            variables: {
                input: {
                    type: "ERP",
                    contacts,
                }
            }
        })

        console.log(response);
    } catch (ex) {
        console.log("Failed to uypdate ERP contacts", ex);
    }
}
