import FormSelect from "./FormSelect";

const FieldSelectRole = ({ name = "role", placeholder = "Role", label = "Role", ...rest }) => {
    return (
        <FormSelect {...rest} name={name} placeholder={placeholder} label={label} options={[
            { value: '', label: "Select Role" },
            { value: 'WORKER', label: "Remote Worker" },
            { value: 'SUPERVISOR', label: "Supervisor" },
        ]} />
    )
}

export default FieldSelectRole;