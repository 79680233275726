export const usersByCompanyIdAndStatus = /* GraphQL */ `
    query UsersByCompanyIdAndStatus(
        $companyId: ID!
        $status: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        usersByCompanyIdAndStatus(
            companyId: $companyId
            status: $status
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                email
                firstName
                lastName
                role
                avatar
                status
                companyId
                contactNotes
                company {
                    id
                    name
                    logo
                    emergencyResponsePhoneNumber
                    contactNotes
                    createdAt
                    updatedAt
                }
                erpArea {
                    id
                    name
                    contactInfo
                    contactNotes
                    companyId
                    emergencyContacts {
                        items {
                            id
                            name
                            phone
                            email
                            priority
                            notificationTypes
                            notifyBySMS
                            notifyByEmail
                        }
                    }
                }
                erpAreaId
                mode
                satelliteDeviceId
                satelliteDeviceType
                checkIns(sortDirection: DESC, limit: 1) {
                    items {
                        timestamp
                        location {
                            lat
                            lng
                        }
                        status
                    }
                }
                checkInProtocol
                phoneNumber
                shiftStart
                nextCheckIn
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const getDirections = /* GraphQL */ `
    query GetDirections($input: DirectionsInput) {
        getDirections(input: $input) {
            geocoded_waypoints {
                geocoder_status
                partial_match
                place_id
                types
            }
            routes {
                legs {
                    start_address
                    end_address
                    start_location {
                        lat
                        lng
                    }
                    end_location {
                        lat
                        lng
                    }
                    duration {
                        text
                        value
                    }
                    distance {
                        text
                        value
                    }
                    steps {
                        duration {
                            text
                            value
                        }
                        polyline {
                            points
                        }
                    }
                }
                waypoint_order
                overview_path {
                    lat
                    lng
                }
                overview_polyline
                warnings
            }
        }
    }
`;

export const checkInsByCompanyId = /* GraphQL */ `
    query CheckInsByCompanyId($companyId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCheckInFilterInput, $limit: Int, $nextToken: String) {
        checkInsByCompanyId(companyId: $companyId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                userId
                timestamp
                location {
                    lat
                    lng
                    heading
                    speed
                    altitude
                }
                status
                user {
                    email
                    firstName
                    lastName
                    role
                    avatar
                    status
                    phoneNumber
                    companyId
                    checkInProtocol
                    shiftStart
                    nextCheckIn
                    contactNotes
                    createdAt
                    updatedAt
                    manualTimestamp
                    mode
                    satelliteDeviceId
                    satelliteDeviceType
                    erpArea {
                        id
                        name
                        contactInfo
                        contactNotes
                        companyId
                        emergencyContacts {
                            items {
                                id
                                name
                                phone
                                email
                                priority
                                notificationTypes
                                notifyBySMS
                                notifyByEmail
                            }
                        }
                    }
                    erpAreaId
                }
                battery {
                    batteryLevel
                    batteryState
                    lowPowerMode
                }
                companyId
                company {
                    id
                    name
                    logo
                    createdAt
                    updatedAt
                    emergencyResponsePhoneNumber
                    contactNotes
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listCheckIns = /* GraphQL */ `
    query ListCheckIns($userId: String, $filter: ModelCheckInFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listCheckIns(userId: $userId, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
            items {
                userId
                location {
                    lat
                    lng
                    heading
                    speed
                    altitude
                }
                timestamp
                status
                battery {
                    batteryLevel
                    batteryState
                    lowPowerMode
                }
                companyId
                company {
                    id
                    name
                    logo
                    createdAt
                    updatedAt
                    emergencyResponsePhoneNumber
                    contactNotes
                }
                user {
                    email
                    firstName
                    lastName
                    role
                    avatar
                    status
                    phoneNumber
                    contactNotes
                    companyId
                    checkInProtocol
                    shiftStart
                    nextCheckIn
                    createdAt
                    updatedAt
                    manualTimestamp
                    mode
                    satelliteDeviceId
                    satelliteDeviceType
                    satelliteDeviceSMS
                    erpAreaId
                    erpArea {
                        id
                        name
                        contactInfo
                        contactNotes
                        companyId
                        emergencyContacts {
                            items {
                                id
                                name
                                phone
                                email
                                priority
                                notificationTypes
                                notifyBySMS
                                notifyByEmail
                            }
                        }
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listCompanies = /* GraphQL */ `
    query ListCompanies($filter: ModelCompanyFilterInput, $limit: Int, $nextToken: String) {
        listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                logo
                emergencyResponsePhoneNumber
                contactNotes
                erpAreas {
                    items {
                        id
                        name
                        contactInfo
                        contactNotes
                        emergencyContacts {
                            items {
                                id
                                priority
                                name
                                email
                                phone
                                notifyBySMS
                                notifyByEmail
                                notificationTypes
                                erpAreaId
                                createdAt
                                updatedAt
                            }
                        }
                        companyId
                        createdAt
                        updatedAt
                    }
                    nextToken
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const getCompany = /* GraphQL */ `
    query GetCompany($id: ID!) {
        getCompany(id: $id) {
            id
            name
            logo
            emergencyResponsePhoneNumber
            contactNotes
            erpAreas {
                nextToken
                items {
                    id
                    name
                    contactInfo
                    contactNotes
                    companyId
                    createdAt
                    updatedAt
                    emergencyContacts {
                        items {
                            id
                            priority
                            name
                            email
                            phone
                            notifyBySMS
                            notifyByEmail
                            notificationTypes
                            erpAreaId
                            createdAt
                            updatedAt
                        }
                    }
                }
            }
            createdAt
            updatedAt
        }
    }
`;

export const listArchives = /* GraphQL */ `
    query ListArchives(
        $email: String
        $date: ModelStringKeyConditionInput
        $filter: ModelArchiveFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listArchives(email: $email, date: $date, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
            items {
                email
                date
                locationHistory {
                    lat
                    lng
                    heading
                    speed
                    altitude
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const onUpdateCheckIn = /* GraphQL */ `
    subscription OnUpdateCheckIn {
        onUpdateCheckIn {
            userId
            timestamp
            location {
                lat
                lng
                heading
                speed
                altitude
            }
            status
            user {
                email
                firstName
                lastName
                role
                avatar
                status
                companyId
                checkInProtocol
                shiftStart
                nextCheckIn
                phoneNumber
                contactNotes
                createdAt
                updatedAt
                manualTimestamp
                mode
                satelliteDeviceId
                satelliteDeviceType
                satelliteDeviceSMS
                erpArea {
                    id
                    name
                    contactInfo
                    contactNotes
                    companyId
                    emergencyContacts {
                        items {
                            id
                            name
                            phone
                            email
                            priority
                            notificationTypes
                            notifyBySMS
                            notifyByEmail
                        }
                    }
                }
                erpAreaId
            }
            battery {
                batteryLevel
                batteryState
                lowPowerMode
            }
            companyId
            company {
                id
                name
                logo
                contactNotes
                emergencyResponsePhoneNumber
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
    }
`;

export const updateCheckIn = /* GraphQL */ `
    mutation UpdateCheckIn($input: UpdateCheckInInput!, $condition: ModelCheckInConditionInput) {
        updateCheckIn(input: $input, condition: $condition) {
            userId
            timestamp
            location {
                lat
                lng
                heading
                speed
                altitude
            }
            battery {
                batteryLevel
                batteryState
                lowPowerMode
            }
            status
            user {
                email
                firstName
                lastName
                role
                avatar
                status
                companyId
                checkInProtocol
                shiftStart
                nextCheckIn
                phoneNumber
                contactNotes
                manualTimestamp
                satelliteDeviceId
                satelliteDeviceType
                mode
                erpArea {
                    id
                    name
                    contactInfo
                    contactNotes
                    companyId
                    emergencyContacts {
                        items {
                            id
                            name
                            phone
                            email
                            priority
                            notificationTypes
                            notifyBySMS
                            notifyByEmail
                        }
                    }
                }
                erpAreaId
                createdAt
                updatedAt
            }
            companyId
            company {
                id
                name
                logo
                emergencyResponsePhoneNumber
                contactNotes
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
    }
`;

export const updateStatus = /* GraphQL */ `
    mutation UpdateStatus(
        $updateCheckInInput: UpdateCheckInInput!
        $updateCheckInCondition: ModelCheckInConditionInput
        $updateUserInput: UpdateUserInput!
        $updateUserCondition: ModelUserConditionInput
    ) {
        updateUser(input: $updateUserInput, condition: $updateUserCondition) {
            email
            firstName
            lastName
            role
            avatar
            status
            companyId
            company {
                id
                name
                logo
                emergencyResponsePhoneNumber
                contactNotes
                createdAt
                updatedAt
            }
            checkInProtocol
            contactNotes
            shiftStart
            nextCheckIn
            phoneNumber
            manualTimestamp
            erpArea {
                id
                name
                contactInfo
                companyId
                contactNotes
                emergencyContacts {
                    items {
                        id
                        name
                        phone
                        email
                        priority
                        notificationTypes
                        notifyBySMS
                        notifyByEmail
                    }
                }
            }
            erpAreaId
            createdAt
            updatedAt
        }

        updateCheckIn(input: $updateCheckInInput, condition: $updateCheckInCondition) {
            userId
            timestamp
            location {
                lat
                lng
                heading
                speed
                altitude
            }
            battery {
                batteryLevel
                batteryState
                lowPowerMode
            }
            status
            user {
                email
                firstName
                lastName
                role
                avatar
                status
                contactNotes
                companyId
                checkInProtocol
                shiftStart
                nextCheckIn
                phoneNumber
                manualTimestamp
                erpArea {
                    id
                    name
                    contactInfo
                    contactNotes
                    companyId
                    emergencyContacts {
                        items {
                            id
                            name
                            phone
                            email
                            priority
                            notificationTypes
                            notifyBySMS
                            notifyByEmail
                        }
                    }
                }
                erpAreaId
                createdAt
                updatedAt
            }
            companyId
            company {
                id
                name
                logo
                emergencyResponsePhoneNumber
                contactNotes
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
    }
`;

export const listCheckInArchives = /* GraphQL */ `
    query ListCheckInArchives(
        $email: String
        $timestamp: ModelStringKeyConditionInput
        $filter: ModelCheckInArchiveFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listCheckInArchives(email: $email, timestamp: $timestamp, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
            items {
                email
                location {
                    lat
                    lng
                    heading
                    speed
                    altitude
                }
                timestamp
                status
            }
            nextToken
        }
    }
`;

export const getShiftReports = /* GraphQL */ `
    query GetShiftReports($input: ShiftReportInput) {
        getShiftReports(input: $input) {
            startDate
            endDate
            reports {
                report
                company {
                    name
                    id
                    logo
                    emergencyResponsePhoneNumber
                }
            }
        }
    }
`;

export const shiftScheduleByEmail = /* GraphQL */ `
  query CustomShiftScheduleByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelShiftScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shiftScheduleByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        description
        expiry
        createdAt
        updatedAt
        recurringShiftPatterns {
            items {
                id
                email
                name
                description
                scheduleId
                shiftPattern
                startTime
                endTime
                startDate
                endDate
                __typename
            }
        }
        individualShifts {
            items {
                id
                email
                name
                description
                scheduleId
                shiftDate
                startTime
                endTime
                __typename
            }
        }
        user {
            firstName
            lastName
            role
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const shiftSchedulesByCompanyId = /* GraphQL */ `
  query CustomShiftSchedulesByCompanyId(
    $companyId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelShiftScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shiftSchedulesByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        companyId
        name
        description
        expiry
        createdAt
        updatedAt
        __typename
        recurringShiftPatterns(limit: 999) {
            items {
                id
                email
                name
                description
                scheduleId
                shiftPattern
                startTime
                endTime
                startDate
                endDate
                __typename
            }
        }
        individualShifts(limit: 999) {
            items {
                id
                email
                name
                description
                scheduleId
                shiftDate
                startTime
                endTime
                __typename
            }
        }
        user {
            firstName
            lastName
            role
        }
      }
      nextToken
      __typename
    }
  }
`;

export const createShiftSchedule = /* GraphQL */ `
  mutation CustomCreateShiftSchedule(
    $input: CreateShiftScheduleInput!
    $condition: ModelShiftScheduleConditionInput
  ) {
    createShiftSchedule(input: $input, condition: $condition) {
      id
      email
      companyId
      name
      description
      expiry
      recurringShiftPatterns(limit: 999) {
            items {
                id
                email
                name
                description
                scheduleId
                shiftPattern
                startTime
                endTime
                startDate
                endDate
                __typename
            }
        }
        individualShifts(limit: 999) {
            items {
                id
                email
                name
                description
                scheduleId
                shiftDate
                startTime
                endTime
                __typename
            }
        }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query CustomListUsers(
    $email: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
        company {
          name
          id
        }
        erpArea {
          id
          name
        }
      }
      nextToken
      __typename
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
        company {
            id
            name
        }
        erpArea {
            id
            name
        }
      }
      nextToken  
      __typename
    }
  }
`;