import { API } from "aws-amplify";
import { customDeleteCompany } from "../graphql/mutations";

export default async ({ id }) => {
    try {
        const {
            data: { customDeleteCompany: response },
        } = await API.graphql({
            query: customDeleteCompany,
            variables: {
                input: {
                    companyId: id,
                },
            },
        });
        return response;
    } catch (ex) {
        console.error("Failed to update company", ex);
    }
};
