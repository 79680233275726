import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectActiveCheckIn, setActiveCheckIn } from '../common/slices/checkIn';
import { selectModalState, setModalState } from '../slices/app';
import { RedButton } from './Buttons';
import MapCheckIn from './MapCheckIn';
import MapControls from './MapControls';
import ModalUserDetails from './ModalUserDetails';
import { useNavigate } from 'react-router-dom';
import { H1, P } from './Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import theme from '../common/theme';
import logo from '../assets/logo.svg';
import { Logo, TopNavContainer } from './TopNav';

const Container = styled.div`
    background-color: ${theme.palette.border};
    color: white;
    margin: 0 auto;
    width: 50%;
    padding: 3rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
`

const SubscriptionSuspended = () => {
    const nav = useNavigate();
    const logout = async () => {
        await Auth.signOut();
        nav('/auth/login')
    }

    return (
        <>
            <TopNavContainer style={{ paddingBottom: '1rem' }}>
                <Logo src={logo} />
            </TopNavContainer>
            <Container>
                <H1>Subscription Suspended</H1>
                <P>You have an overdue balance on your account.</P>
                <P>This area is restricted until the overdue balance on your account is settled.  If you believe this is an error or need access for a specific reason, please contact our support team.</P>
                <RedButton onClick={logout}>Logout</RedButton>
            </Container>
        </>
    )
}

export default SubscriptionSuspended;
