const onShiftStatuses = [
    "JOURNEY",
    "JOURNEY_OK",
    "JOURNEY_BREAK",
    "JOURNEY_NOT_RESPONDING",
    "JOURNEY_ACCIDENT",
    "JOURNEY_ALTERNATE_ROUTE",
    "JOURNEY_PANIC",
]


export default (status) => {
    return onShiftStatuses.indexOf(status) > -1;
}