export default (protocol) => {
    const map = {
        THIRTY: "30 Minutes",
        SIXTY: "1 Hour",
        NINETY: "1.5 Hours",
        ONETWENTY: "2 Hours",
        TWOFORTY: "4 Hours",
    };

    return map[protocol];
};
