

import S3Image from "./S3Image";

const S3ImageCheckIn = ({ status, ...props }) => {
    return (
        <S3Image
            imgKey={`${status}.png`}
            {...props}
        />
    )
}

export default S3ImageCheckIn;