import { useEffect, useState } from "react"
import { BlueButton, ButtonBase } from "./Buttons";
import Details from "./Details";
import { H2 } from "./Typography";
import Row from "./Row";

const GRID_ATLAS_API_KEY = 'ga_4da41a7dd3f';

export default ({ checkIn }) => {
    const [lsdData, setLSDData] = useState(null);

    useEffect(() => {
        return () => {
            setLSDData(null);
        }
    }, [])

    const fetchLSD = async () => {
        try {

            const coords = encodeURIComponent(`${checkIn.location.lat},${checkIn.location.lng}`)
            const resp = await fetch(`https://www.gridatlas.com/api/public/v2/${GRID_ATLAS_API_KEY}/lookup/latlng/${coords}`)
            const data = await resp.json();
            setLSDData(data);
        } catch (ex) {
            console.log("Failed to fetch LSD", ex);
        }
    }

    if (!lsdData) {
        return <BlueButton onClick={fetchLSD}>Convert to LSD</BlueButton>
    }

    return (
        <>
            <H2>Land Description</H2>
            <Details title="LSD" data={lsdData.data.queries[0].result?.dls?.lsd} />
            <Row>
                <Details title="Address" data={lsdData.data.queries[0].result?.address?.street} />
                <Details title="City" data={lsdData.data.queries[0].result?.address?.city} />
                <Details title="Province" data={lsdData.data.queries[0].result?.dls?.province} />
            </Row>
            <Row>
                <Details title="LLD" data={lsdData.data.queries[0].result?.dls?.lld} />
                <Details title="NTS" data={lsdData.data.queries[0].result?.dls?.nts} />
                <Details title="UTM" data={lsdData.data.queries[0].result?.utm?.brief} />
            </Row>
        </>
    )
}