import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import logoImg from '../assets/logo.svg'
import { BlueButton, OutlineButton } from "./Buttons"
import { Content, Footer } from "./Layout"
import { H1, P, P2 } from "./Typography"
import { TopNavContainer } from "./TopNav"
import Row from "./Row"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import Divider from "./Divider"


const MessageContainer = styled.div`
    flex: 1;
    background: rgba(41, 44, 84, 0.5);
    padding: 1rem;
    margin: 0 auto;
    max-width: 800px;
`

const DL = styled.dl`
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    margin: 0;
    padding: 1rem 0 0 0;
`

const DT = styled.dt`
`

const DD = styled.dd`
    font-weight: 700;
`

export default () => {
    const nav = useNavigate();

    return (
        <>
            <TopNavContainer style={{ paddingBottom: '1rem' }}>
                <img src={logoImg} style={{ width: '100px' }} />
            </TopNavContainer>

            <Content style={{ gap: '1rem', padding: '2rem 0', width: '75%', maxWidth: '900px', margin: '0 auto' }}>
                <Row style={{ gap: '2rem' }}>
                    <div style={{ flex: 1.25, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <FontAwesomeIcon icon={faCircleCheck} size="2x" />
                        <H1>Welcome to Be Safe! You're all set!</H1>
                        <P style={{ lineHeight: '1.2rem' }}>Check your email inbox for your welcome email with your temporary password required to login for the first time. If your email does not show up right away, be sure to check your junk folder.</P>
                        <P style={{ lineHeight: '1.2rem' }}>An account manager has been automatically assigned to your account. If you have any questions, feedback, or would like a one-on-one training session, please reach out to your account manager.</P>
                    </div>
                    <MessageContainer>
                        <P2>Account manager contact information</P2>
                        <Divider />
                        <DL>
                            <DT>Name</DT>
                            <DD>Colin Rees</DD>
                            <DT>Phone Number</DT>
                            <DD>+1 416 312 6366</DD>
                            <DT>Email</DT>
                            <DD><a href="mailto:crees@be-safe.ca">crees@be-safe.ca</a></DD>
                        </DL>
                    </MessageContainer>
                </Row>
                <Divider />
                <BlueButton onClick={() => nav("/")}>Login Now</BlueButton>
            </Content>
            <Footer />
        </>
    )
}
