import { Auth } from "@aws-amplify/auth";

export default async () => {
    try {
        const u = await Auth.currentAuthenticatedUser();
        return u;
    } catch (e) {
        console.error("failed to get current authenticated user", e);
        return false;
    }
}