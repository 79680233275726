import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { deleteCompany, selectActiveCompany, updateCompany } from '../common/slices/company';
import theme from '../common/theme';
import { ButtonBase, RedButton } from "./Buttons";
import Divider from "./Divider";
import FormERP from "./FormERP";
import FormTextInput from "./FormTextInput";
import Modal from './Modal';
import { H1 } from "./Typography";
import FormTextArea from "./FormTextArea";
import { NOTIFICATION_TYPE_MAP } from "./FormSelectNotificationTypes";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 1rem;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const Footer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid ${theme.palette.border}
`

const unmarshall = ({ id, name, erpAreas, contactNotes, createdAt }) => ({
    id,
    name,
    contactNotes,
    createdAt,
    erpAreas: erpAreas.items.map(area => ({
        ...area,
        emergencyContacts: area.emergencyContacts?.items?.map(({ notifyBy, notificationTypes, ...i }) => ({
            ...i,
            notificationTypes: notificationTypes.map(nt => nt.value),
            notifyBySMS: !!notifyBy?.find(v => v?.value === "SMS"),
            notifyByEmail: !!notifyBy?.find(v => v?.value === "EMAIL"),
        }))
    }))
})

const marshall = ({ id, name, erpAreas, contactNotes, createdAt }) => ({
    id,
    name,
    contactNotes,
    createdAt,
    erpAreas: {
        items: erpAreas.items.map(area => ({
            ...area,
            emergencyContacts: {
                items: area.emergencyContacts?.items?.map(({ notifyBy, notificationTypes, ...i }) => ({
                    ...i,
                    notificationTypes: notificationTypes.map(nt => ({ value: nt, label: NOTIFICATION_TYPE_MAP[nt] })),
                    notifyBy: [
                        i.notifyBySMS ? ({ value: "SMS", label: "sms" }) : undefined,
                        i.notifyByEmail ? ({ value: "Email", label: "email" }) : undefined,
                    ]
                }))
            }
        }))
    }
})

const ModalAddCompany = ({ show, onClose }) => {
    const activeCompany = useSelector(selectActiveCompany);
    const dispatch = useDispatch();

    const onSaveClick = async (values) => {
        const toUpdate = unmarshall(values);
        await dispatch(updateCompany(toUpdate));
        onClose();
    }

    const onDelete = async () => {
        const confirmed = window.confirm(`Are you sure you want to delete ${activeCompany.name}?`)
        if (confirmed) {
            const success = await dispatch(deleteCompany(activeCompany));
            if (success) { onClose(); }
            else { window.alert("Failed to delete company") }
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting, isDirty },
    } = useForm({
        defaultValues: marshall(activeCompany)
    })

    const fieldProps = { control, errors };

    return (
        <Modal
            show={show}
            onClose={() => {
                if (!isDirty) {
                    onClose();
                }
            }}
        >
            <Container>
                <Header>
                    <H1>Manage Company</H1>
                    <ButtonBase onClick={onClose} disabled={isSubmitting}><FontAwesomeIcon icon={faTimesCircle} size={'2x'} /></ButtonBase>
                </Header>
                <FormTextInput
                    {...fieldProps}
                    rules={{ required: true }}
                    name="name"
                    placeholder="Enter Company Name"
                    label="Company Name"
                />
                <FormTextArea {...fieldProps} name="contactNotes" label="Notes" placeholder="Enter notes for this company (ie. don't contact after 6pm)" />
                <Divider />
                <FormERP {...fieldProps} />
                <Footer>
                    <RedButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid}>Save</RedButton>
                    <RedButton onClick={handleSubmit(onDelete)} disabled={isSubmitting || !activeCompany?.id} style={{ marginLeft: 'auto' }}>Delete</RedButton>
                    <ButtonBase onClick={onClose} disabled={isSubmitting} >Cancel</ButtonBase>
                </Footer>
                <small>id: {activeCompany?.id}</small>
            </Container>
        </Modal>
    )
}

export default ModalAddCompany