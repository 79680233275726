import { API } from "aws-amplify";
import { updateCheckIn } from "../graphql/customQueries";
import insertCheckIn from "./insertCheckIn";

export default async ({ email, location, status, companyId, battery }) => {
    try {
        const {
            data: { updateCheckIn: updatedCheckIn },
        } = await API.graphql({
            query: updateCheckIn,
            variables: {
                input: {
                    userId: email,
                    timestamp: new Date(),
                    status,
                    location,
                    companyId,
                    battery
                },
            },
        });
        return updatedCheckIn;
    } catch (ex) {
        console.log("Failed to update checkIn", ex);
        console.log("trying to insert instead");
        return insertCheckIn({ email, location, status, companyId });
    }
};
