import { faChevronLeft, faChevronRight, faEdit, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { orderBy } from 'lodash'
import { useEffect, useState } from "react"
import { formatPhoneNumber } from "react-phone-number-input"
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components"
import { listCompanies, nextPage, prevPage, searchCompanies, selectCompanyList, setActiveCompany } from '../common/slices/company'
import { selectUser } from '../common/slices/user'
import theme from "../common/theme"
import { Accordion, AccordionItem, AccordionSubItem } from "./AccordionCompany"
import { AdminButton, ButtonBase } from "./Buttons"
import { UncontrolledFormTextInput } from "./FormTextInput"
import ModalAddCompany from "./ModalAddCompany"
import { TabContent } from "./Tabs"
import { ButtonIcon, P, P2 } from "./Typography"
import Badge from './Badge';
import Details from "./Details"
import { FooterButton, TableFooter } from "./Table"

const ScreenHeader = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`

const CompanyTable = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 1rem;
    `

const ERPTable = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.5rem 1rem;
    `

const ERPData = styled.div`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const TableHeader = styled(ERPTable)`
    background: #000;
    font-weight: 700;
`

const ContactTableHeader = styled(TableHeader)`
    border-top: 1px solid ${theme.palette.lightBlue};
`



const types = {
    SHIFT_NOT_RESPONDING: 'Missed Check-in',
    SHIFT_PANIC: 'Shift Panic',
    SHIFT_ACCIDENT: 'Shift Accident',
    JOURNEY_ACCIDENT: 'Journey Accident',
    JOURNEY_PANIC: "Journey Panic",
}

const TableCompany = () => {
    const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
    const companyList = useSelector(selectCompanyList);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(listCompanies(user.companyId, user.role))
    }, [dispatch, user.companyId, user.role])

    const onEditClick = (company) => (e) => {
        e.stopPropagation();
        dispatch(setActiveCompany(company));
        setShowAddCompanyModal(true);
    }

    const onNewCompany = () => {
        dispatch(setActiveCompany({
            name: "",
            erpAreas: { items: [] }
        }));
        setShowAddCompanyModal(true);
    }

    const onSearch = (e) => {
        dispatch(searchCompanies(e.target.value));
    }

    return (
        <TabContent tabId={2}>
            <ScreenHeader>

                <UncontrolledFormTextInput type="search" onChange={onSearch} name="search" placeholder="Enter name, email, or phone number" style={{ padding: '0.75rem', background: 'rgba(16, 18, 36, 1)', color: 'white', border: 'none' }} />

                <AdminButton onClick={onNewCompany}><ButtonIcon icon={faPlusCircle} /> Add Company</AdminButton>
            </ScreenHeader>
            <CompanyTable>
                <TableHeader>
                    <ERPData>Name</ERPData>
                    <ERPData>Date Created</ERPData>
                    <ERPData>Date Modified</ERPData>
                    <ERPData># of ERP Groups</ERPData>
                </TableHeader>

                <Accordion>
                    {
                        orderBy(companyList, c => c.name?.toLowerCase(), 'asc')?.map((company, ci) => (
                            <AccordionItem
                                id={ci}
                                header={company.name}
                                createdAt={company.createdAt}
                                updatedAt={company.updatedAt}
                                erpAreas={company.erpAreas?.items?.length}
                                key={company.id}
                                actions={
                                    <ButtonBase
                                        onClick={onEditClick(company)}
                                        style={{ color: 'white', padding: '0.5rem 1rem', background: theme.palette.blue }} ><FontAwesomeIcon icon={faEdit} /></ButtonBase>
                                }
                            >
                                <Accordion>

                                    {
                                        company?.contactNotes &&
                                        <div style={{ padding: '1rem 0 0 3rem' }}>
                                            <Details title={`${company?.name} Contact Notes`} data={company?.contactNotes} />
                                        </div>
                                    }
                                    {
                                        orderBy(company?.erpAreas?.items, c => c.name?.toLowerCase()).map((erp, i) => (
                                            <AccordionSubItem header={`${erp.name}`} key={erp.id} id={i}>
                                                {
                                                    erp?.contactNotes &&
                                                    <Details title={`${erp?.name} Contact Notes`} data={erp?.contactNotes} />
                                                }
                                                <ContactTableHeader>
                                                    <ERPData>Priority</ERPData>
                                                    <ERPData>Name</ERPData>
                                                    <ERPData>Phone Number</ERPData>
                                                    <ERPData>Email</ERPData>
                                                    <ERPData>Notify By</ERPData>
                                                    <ERPData>Notification Types</ERPData>
                                                </ContactTableHeader>
                                                {
                                                    orderBy(erp.emergencyContacts?.items, "priority").map(contact => (
                                                        <ERPTable key={contact.id}>
                                                            <ERPData>{contact.priority}</ERPData>
                                                            <ERPData>{contact.name}</ERPData>
                                                            <ERPData>{formatPhoneNumber(contact.phone)}</ERPData>
                                                            <ERPData>{contact.email}</ERPData>
                                                            <ERPData>{contact.notifyBySMS ? <Badge color="#606060">sms</Badge> : ''} {contact.notifyByEmail ? <Badge color="#606060">email</Badge> : ''}</ERPData>
                                                            <ERPData>{contact.notificationTypes?.map(type => types[type]).join(', ')}</ERPData>
                                                        </ERPTable>
                                                    ))
                                                }
                                                {
                                                    !erp.emergencyContacts?.items?.length &&
                                                    <P style={{ margin: 0, padding: '1rem' }}>No emergency contacts have been created for this ERP Group.</P>
                                                }
                                            </AccordionSubItem>
                                        ))
                                    }
                                    {
                                        !company.erpAreas?.items?.length &&
                                        <P style={{ margin: 0, padding: '1rem' }}>There are no ERP Areas created for this company yet.</P>
                                    }
                                </Accordion>
                            </AccordionItem>
                        ))
                    }
                </Accordion>
            </CompanyTable>
            <TableFooter>
                <FooterButton onClick={() => dispatch(prevPage(user?.companyId, user?.role))}><FontAwesomeIcon icon={faChevronLeft} /></FooterButton>
                <FooterButton onClick={() => dispatch(nextPage(user?.companyId, user?.role))}><FontAwesomeIcon icon={faChevronRight} /></FooterButton>
            </TableFooter>
            {showAddCompanyModal && <ModalAddCompany show onClose={() => {
                setShowAddCompanyModal(false)
            }} />}
        </TabContent >
    )
}

export default TableCompany