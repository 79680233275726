import React from "react";
import { BlackButton, BlueButton, ButtonBase, DarkButton } from "./Buttons";
import Modal from "./Modal";
import Row from "./Row";
import { H1, P } from "./Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import isNotification from "../common/utils/isNotification";
import { useSelector } from "react-redux";
import { selectArchive } from "../slices/archive";
import { Table, TableData, TableHeader, TableRow } from "./Table";
import moment from "moment";
import friendlyStatus from "../common/utils/friendlyStatus";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const ModalEventHistory = ({ onClose }) => {
    const archive = useSelector(selectArchive);
    const events = archive.filter(a => isNotification(a.status))

    return (
        <Modal show size="33%"
            bodyStyle={{
                maxHeight: '75%', overflowY: 'auto'
            }}
        >
            <Container>
                <Row>
                    <H1>Event History</H1>
                    <ButtonBase onClick={onClose} style={{ marginLeft: 'auto' }}><FontAwesomeIcon icon={faTimes} size='16px' color="#000" /></ButtonBase>
                </Row>
                <Table style={{}}>
                    <thead>
                        <tr style={{ color: 'white' }}>
                            <TableHeader>Timestamp</TableHeader>
                            <TableHeader>Location</TableHeader>
                            <TableHeader>Status</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            events.map(event => (
                                <tr>
                                    <TableData>{moment(event.timestamp).format("YYYY/MM/DD [at] h:mm a")}</TableData>
                                    <TableData>{`${event?.location?.lat?.toFixed(3)}, ${event?.location?.lng?.toFixed(3)}`}</TableData>
                                    <TableData>{friendlyStatus(event?.status)}</TableData>
                                </tr>
                            ))
                        }
                        {
                            !events.length && <tr><TableData colSpan={3}>There were no events reported on this day</TableData></tr>
                        }

                    </tbody>
                </Table>


                <DarkButton onClick={onClose}>Close</DarkButton>
            </Container>
        </Modal >
    );
};

export default ModalEventHistory;
