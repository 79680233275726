import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { selectActiveUser } from "../common/slices/user";
import theme from '../common/theme';
import { ButtonBase, RedButton } from "./Buttons";
import Modal from './Modal';
import Row from './Row';
import { H1 } from "./Typography";
import FormTextInput from "./FormTextInput";
import FormTextArea from "./FormTextArea";
import FormDatePicker from "./FormDatePicker";
import { createShiftSchedule } from "../common/slices/schedule";


const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`

const Footer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid ${theme.palette.border}
`



const ModalAddSchedule = ({ onClose }) => {
    const dispatch = useDispatch();
    const activeUser = useSelector(selectActiveUser);


    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting, isValid, isDirty },
        watch,
    } = useForm({
        values: {
            email: activeUser.email,
            companyId: activeUser.companyId,
            name: "Default"
        }
    })

    const fieldProps = { control, errors }

    const onSubmit = async (values) => {
        await dispatch(createShiftSchedule(values));
        onClose();
    }


    return (
        <Modal
            show
            onClose={() => { if (!isDirty) { onClose() } }}
            size="sm"
        >
            <Container>
                <Header>
                    <H1>New Schedule</H1>
                    <ButtonBase onClick={onClose}><FontAwesomeIcon icon={faTimesCircle} size={'2x'} /></ButtonBase>
                </Header>
                <FormTextInput {...fieldProps} name="name" label="Name" placeholder="Enter schedule name" rules={{ required: true }} />
                <FormTextArea numberOfLines={3} {...fieldProps} name="description" label="Description" placeholder="Enter schedule description" />
                <Footer>
                    <RedButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting || !isValid}>Save</RedButton>
                    <ButtonBase onClick={onClose}>Cancel</ButtonBase>
                </Footer>
            </Container>
        </Modal>
    )
}

export default ModalAddSchedule