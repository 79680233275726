export default (status) => {
    const map = {
        OFF: "Off",
        JOURNEY: "On Journey",
        SHIFT: "On Shift",
        SHIFT_OK: "On Shift",
        JOURNEY_OK: "On Journey",
        SHIFT_ACCIDENT: "Accident",
        JOURNEY_ACCIDENT: "Accident",
        SHIFT_NOT_RESPONDING: "Missed check in",
        JOURNEY_NOT_RESPONDING: "Missed check in",
        SHIFT_PANIC: "Panic",
        JOURNEY_PANIC: "Panic",
    };

    return map[status];
};
