export const colors = {
    green: "#42A776",
    blue: "#171931",
    lightBlue: "#4385F3",
    red: "#B53434",
    yellow: "#FFC700"
};

export const UPDATE_LOCATION_TASK = "UPDATE_LOCATION";
export const UPDATE_LOCATION_TASK_JOURNEY = "UPDATE_LOCATION_JOURNEY";
export const BACKGROUND_NOTIFICATION_TASK = "BACKGROUND_NOTIFICATION_TASK";

export const checkInProtocols = {
    THIRTY: "THIRTY",
    SIXTY: "SIXTY",
    NINETY: "NINETY",
    ONETWENTY: "ONETWENTY",
    TWOFORTY: "TWOFORTY",
}

export const roles = {
    OPERATOR: "OPERATOR",
    SUPERVISOR: "SUPERVISOR",
    REMOTE_WORKER: "WORKER",
}

export const statuses = {
    JOURNEY: "JOURNEY",
    JOURNEY_BREAK: "JOURNEY_BREAK",
    JOURNEY_NOT_RESPONDING: "JOURNEY_NOT_RESPONDING",
    JOURNEY_ACCIDENT: "JOURNEY_ACCIDENT",
    JOURNEY_ALTERNATE_ROUTE: "JOURNEY_ALTERNATE_ROUTE",
    JOURNEY_PANIC: "JOURNEY_PANIC",
    JOURNEY_OK: "JOURNEY_OK",
    SHIFT: "SHIFT",
    SHIFT_BREAK: "SHIFT_BREAK",
    SHIFT_NOT_RESPONDING: "SHIFT_NOT_RESPONDING",
    SHIFT_ACCIDENT: "SHIFT_ACCIDENT",
    SHIFT_PANIC: "SHIFT_PANIC",
    SHIFT_OK: "SHIFT_OK",
    OFF: "OFF",
}

export const TERMS_VERSION = "1.1"

export const EMAIL_PATTERN = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;