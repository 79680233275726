import styled from "styled-components"
import theme from "../common/theme"
import { ButtonBase } from "./Buttons"

export const Table = styled.table`
    padding: 1rem 2rem;
    border-collapse: collapse;
    overflow: hidden;
    margin-bottom: 1rem;
    width: 100%;
`

export const TableHeader = styled.th`
    text-align: left;
    padding: 0.5rem 1rem;
    border-bottom: 2px solid ${theme.palette.blue};
    background-color: ${theme.palette.dark};
`

export const TableData = styled.td`
    text-align: left;
    padding: 0.5rem 1rem;
    text-wrap: nowrap;
    overflow: hidden;
`

export const TableRow = styled.tr`
    cursor: pointer;
    border-bottom: 1px solid rgba(41, 44, 84, 1);

    &:hover {
        background-color: rgb(16, 18, 36);
        border: 1px solid rgb(16, 18, 36);
    }
`;

export const ScreenHeader = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
`

export const TableFooter = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    background: rgba(16, 18, 36, 1);
    justify-content: flex-end;
`

export const FooterButton = styled(ButtonBase)`
    padding: 1rem;
    border: 1px solid ${theme.palette.border};
    color: white;
`