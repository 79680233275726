import styled from "styled-components"
import theme from "../common/theme"
import FormTextInput from "./FormTextInput"

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    align-items: stretch;
    align-content: center;
    justify-content: center;
    gap: 1rem;
`

export const Logo = styled.img`
    width: 33%;
    max-width: 400px;
    min-width: 200px;
    padding: 3rem 0;
    align-self: center;
`

export const LoginContainer = styled.div`
    background-color: ${theme.palette.green};
    border-top-left-radius: 7rem;
    border-bottom-right-radius: 7rem;
    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    flex: 1;
    `

export const FormContainer = styled.div`
    width: 500px;
    align-self: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
`

export const Input = styled(FormTextInput)`
    padding: 1rem;
    background: rgba(62, 147, 105, 1);
    border: none;
    color: white;
    ::placeholder {
        color: white;
    }
`