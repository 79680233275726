const onShiftStatuses = [
    "SHIFT",
    "SHIFT_OK",
    "SHIFT_BREAK",
    "SHIFT_NOT_RESPONDING",
    "SHIFT_ACCIDENT",
    "SHIFT_PANIC",
]


export default (status) => {
    return onShiftStatuses.indexOf(status) > -1;
}