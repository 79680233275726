import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import JOURNEY_OK from '../common/assets/JOURNEY_OK.png';
import OFF from '../common/assets/OFF.png';
import SHIFT_OK from '../common/assets/SHIFT_OK.png';
import SHIFT_PANIC from '../common/assets/SHIFT_PANIC.png';
import { statuses } from "../common/constants";
import { selectFilter, setFilter } from "../common/slices/checkIn";
import theme from "../common/theme";
import { selectModalState } from "../slices/app";

const Control = styled(({ icon, ...props }) => {
    return (
        <button {...props}>{icon} {props.title}</button>
    )
})`
    padding: 1rem 0.5rem;
    line-height: 26px;
    background-color: ${props => props.color || props.active ? theme.palette.lightBlue : 'rgba(41, 44, 84, 1)'};
    border: none;
    color: white;
    font-weight: 700;
    margin: 0;
    cursor: pointer;
    transition-duration: 0.3s;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 1rem;

    &:hover {
        background-color: ${props => props.hoverColor || theme.palette.lightBlue};
        color: white;
    }
`

const Icon = styled.img`
    vertical-align: middle;
`

const MapControls = () => {
    const dispatch = useDispatch();
    const filter = useSelector(selectFilter);

    return (
        <>
            <Control active={filter === null} onClick={() => dispatch(setFilter(null))} title='Show All' />
            <Control active={filter === statuses.OFF} icon={<Icon src={OFF} />} onClick={() => dispatch(setFilter(statuses.OFF))} title='Off Shift' />
            <Control active={filter === statuses.SHIFT} icon={<Icon src={SHIFT_OK} />} onClick={() => dispatch(setFilter(statuses.SHIFT))} title='On Shift' />
            <Control active={filter === statuses.JOURNEY} icon={<Icon src={JOURNEY_OK} />} onClick={() => dispatch(setFilter(statuses.JOURNEY))} title='On Journey' />
            <Control active={filter === 'PANIC'} icon={<Icon src={SHIFT_PANIC} />} onClick={() => dispatch(setFilter('PANIC'))} title='Panic mode' />
        </>
    )
}

export default MapControls