import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonBase } from "./Buttons";
import { faInbox, faMailForward, faMessage, faPaperPlane, faPencil, faPhone, faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import theme from "../common/theme";
import { orderBy } from 'lodash';
import { formatPhoneNumber } from "react-phone-number-input";

export const ERPActions = ({ onEditClick }) => {
    return (
        <ButtonBase onClick={async (e) => {
            e.stopPropagation();
            if (onEditClick) {
                await onEditClick();
            }
        }}><FontAwesomeIcon icon={faPencil} /></ButtonBase>
    );
}

const noop = () => { };

const ERPContactList = styled.div`
    display: flex;
    flex-direction: column;
`

const ERPContact = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    align-items: center;
    gap: 1rem;

    background-color: black;

    &:nth-child(even) {
        background-color: transparent;
    }

    &:hover {
        background-color: ${theme.palette.border};
        color: white;
    }

    span {
        margin-right: auto;
    }
`

export const ERPEmergencyContactList = ({ contacts, onClick }) => {
    return (
        <ERPContactList>
            {orderBy(contacts, 'priority', 'asc').map(contact => (
                <ERPContact
                    key={contact.id}
                    onClick={() => onClick && onClick(contact)}
                >
                    <FontAwesomeIcon icon={faPhoneSquare} />
                    <span>{contact.name}</span>
                    {formatPhoneNumber(contact.phone)}
                </ERPContact>
            ))}
        </ERPContactList>
    );
}