import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import friendlyProtocol from '../common/utils/friendlyProtocol';
import isOnShift from '../common/utils/isOnShift';
import ProgressBar from './Progress';
import { P2 } from './Typography';

const NextCheckIn = ({ checkIn }) => {
    const [status, setStatus] = useState({ progress: 0, alarm: false, nextCI: null });
    const timeoutRef = useRef();
    const map = {
        THIRTY: 30,
        SIXTY: 60,
        NINETY: 90,
        ONETWENTY: 120,
        TWOFORTY: 240,
    };

    const updateProgress = () => {
        const nextCI = moment(checkIn?.user?.manualTimestamp).add(map[checkIn?.user?.checkInProtocol], 'minute');
        const diffSeconds = nextCI.diff(moment(), 'minute', true);
        const progress = (1 - ((diffSeconds) / map[checkIn?.user?.checkInProtocol])) * 100;
        const alarm = moment(checkIn?.user?.manualTimestamp).add(map[checkIn?.user?.checkInProtocol], 'minute') < moment();
        setStatus({
            nextCI,
            diffSeconds,
            progress,
            alarm,
        });
    }

    useEffect(() => {
        timeoutRef.current = setInterval(updateProgress, 1000);
        updateProgress()
        return () => clearInterval(timeoutRef.current);
    }, [checkIn]);


    if (!isOnShift(checkIn?.status)) {
        return null
    }

    return (
        <div style={{ marginTop: '0.5rem' }}>
            <P2 style={{ color: 'rgba(187, 187, 187, 1)', margin: 0, padding: 0 }}>Time Until Next Check-In</P2>
            <ProgressBar progress={status.progress} alarm={status.alarm} />
            {`Next check-in ${moment.duration(status.diffSeconds, 'm').format('*h:mm [h]')} at ${status.nextCI?.format('h:mm a')}`}{status.alarm ? ' - Late' : ''}<br />
        </div>
    )
}

export default NextCheckIn