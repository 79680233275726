import decodePolyline from "./decodePolyline";
import deleteCompany from './deleteCompany';
import deleteContact from "./deleteContact";
import deleteERP from "./deleteERP";
import deleteUser from './deleteUser';
import endShift from "./endShift";
import getAuthUser from "./getAuthUser";
import getCompany from "./getCompany";
import getDirections from "./getDirections";
import getNextCheckIn from "./getNextCheckIn";
import getSummary from './getSummary';
import getUser from "./getUser";
import insertCompany from "./insertCompany";
import insertCheckIn from "./insertCheckIn";
import insertERP from "./insertERP";
import insertJourney from "./insertJourney";
import insertUser from "./insertUser";
import listArchive from "./listArchive";
import listCheckIns from "./listCheckIns";
import listCheckInsByCompanyId from "./listCheckInsByCompanyId";
import listCompanies from "./listCompanies";
import listUsers from "./listUsers";
import listUserSettings from './listUserSettings';
import listUsersByCompanyId from "./listUsersByCompanyId";
import searchUsers from './searchUsers';
import subscribeCheckIns from "./subscribeCheckIns";
import subscribeUserUpdates from "./subscribeUserUpdates";
import updateAvatar from "./updateAvatar";
import updateCompany from "./updateCompany";
import updateERP from "./updateERP";
import updateUser from "./updateUser";
import updateCheckIn from "./updateCheckIn";
import updateStatus from "./updateStatus";

export default {
    decodePolyline,
    deleteCompany,
    deleteContact,
    deleteERP,
    deleteUser,
    endShift,
    getAuthUser,
    getCompany,
    getDirections,
    getNextCheckIn,
    getSummary,
    getUser,
    insertCompany,
    insertCheckIn,
    insertERP,
    insertJourney,
    insertUser,
    listArchive,
    listCheckIns,
    listCheckInsByCompanyId,
    listCompanies,
    listUsers,
    listUserSettings,
    listUsersByCompanyId,
    searchUsers,
    subscribeCheckIns,
    subscribeUserUpdates,
    updateAvatar,
    updateCompany,
    updateCheckIn,
    updateERP,
    updateUser,
    updateStatus,
};
