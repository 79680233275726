import { API } from "aws-amplify";
import { createNewUser } from "../graphql/mutations";

const insertUser = async ({
    email,
    firstName,
    lastName,
    role,
    companyId,
    contactNotes,
    checkInProtocol,
    phoneNumber,
    erpAreaId,
    satelliteDeviceType,
    satelliteDeviceId,
    satelliteDeviceSMS,
}) => {
    try {
        const {
            data: { createNewUser: newUser },
        } = await API.graphql({
            query: createNewUser,
            variables: {
                input: {
                    email,
                    erpAreaId,
                    companyId,
                    firstName,
                    lastName,
                    phoneNumber,
                    contactNotes,
                    checkInProtocol,
                    role,
                    satelliteDeviceId,
                    satelliteDeviceType,
                    satelliteDeviceSMS,
                },
            },
        });
        return newUser;
    } catch (ex) {
        console.log("failed to insert user", ex);
    }
};

export default insertUser;
