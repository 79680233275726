import { createSelector, createSlice } from "@reduxjs/toolkit";
import service from "../common/service";
import { reset } from "../common/slices/globalActions";

const initialState = {
    modals: {},
    settings: {},
    loading: false,
};

const appSlice = createSlice({
    name: "app",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(reset, () => initialState);
    },
    reducers: {
        setModalState: (state, action) => {
            state.modals[action.payload.modalName] = action.payload.show;
        },
        setSettings: (state, action) => {
            action.payload.map((setting) => (state.settings[setting.name] = JSON.parse(setting.value)));
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const { setModalState, setLoading, setSettings } = appSlice.actions;
export default appSlice.reducer;

export const selectState = (state) => state.app;
export const selectModalState = (modalName) => createSelector(selectState, (state) => state.modals[modalName]);
export const selectSettings = createSelector(selectState, (state) => state.settings);
export const selectLoading = createSelector(selectState, state => state.loading);

export const getUserSettings = (email) => async (dispatch) => {
    const settings = await service.listUserSettings(email);
    dispatch(setSettings(settings));
};
