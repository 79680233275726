import { createSelector, createSlice, createAction, AnyAction } from "@reduxjs/toolkit";
import { API, Auth } from "aws-amplify";
import { reset } from "./globalActions";
import { register } from "../graphql/mutations";

const moduleName = "registration";

const initialState = {
    loading: false,
    status: {
        termsOfService: {},
        accountOwner: {},
        company: {},
        erpGroups: {},
        workers: {},
        payment: {}
    }
};

const slice = createSlice({
    name: moduleName,
    initialState,
    extraReducers: (builder) => {
        builder.addCase(reset, () => initialState);
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        updateStatus: (state, action) => {
            state.status[action.payload.step] = action.payload.status
        }
    },
});

export const { setLoading, updateStatus } = slice.actions;
export default slice.reducer;

export const selectState = (state) => state[moduleName];
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectStatus = createSelector(selectState, (state) => state.status);

export const completeRegistration = () => async (dispatch, getState) => {
    const status = selectStatus(getState());

    const { data } = await API.graphql({
        query: register,
        authMode: "API_KEY",
        variables: {
            input: {
                accountOwner: status.accountOwner.owner,
                company: {
                    name: status.company.company.name,
                    contactNotes: status.company.company.contactNotes,
                    utcOffset: status.company.company.utcOffset.value,
                    customerId: status.payment.state.customerId,
                    erpAreas: status.erpGroups.groups.erpAreas.items.map(i => ({
                        ...i,
                        emergencyContacts: i.emergencyContacts.items
                    }))
                },
                workers: status.workers.workers.workers.map(w => ({
                    ...w,
                    erpAreaId: w.erpAreaId.value,
                })),
                terms: status.termsOfService.acceptedAt,
            }
        }
    })
    return data.register;
}