import _ from 'lodash';
import moment from 'moment';
import { useSelector } from "react-redux";
import isNotification from "../common/utils/isNotification";
import { selectArchive, selectArchiveDate } from "../slices/archive";
import Details from './Details';
import Row from './Row';
import { H2, P2 } from "./Typography";
import { useState } from 'react';
import ModalEventHistory from './ModalEventHistory';
import { ButtonBase } from './Buttons';

const ArchiveDetails = () => {
    const archive = useSelector(selectArchive);
    const date = useSelector(selectArchiveDate);
    const [eventHistory, setEventHistory] = useState(false);

    const Events = () => (
        <>
            {events.length}&nbsp;
            <ButtonBase onClick={() => setEventHistory(true)} style={{ color: 'white' }}>view</ButtonBase>
        </>
    )


    if (!date) {
        return null;
    }

    if (!archive.length) {
        return <P2>Worker was not on a shift on {moment(date).format("YYYY/MM/DD")}</P2>;
    }


    const start = _.minBy(archive.filter(a => a.status !== "OFF"), (c) => moment(c.timestamp).valueOf());
    const end = _.maxBy(archive, (c) => moment(c.timestamp).valueOf());
    const shiftStart = moment(start?.timestamp);
    const shiftEnd = moment(end?.timestamp);
    const events = archive.filter(a => isNotification(a.status));
    const diff = moment(end?.timestamp).diff(moment(start?.timestamp), "seconds");
    const shiftDuration = moment.duration({ seconds: diff }).format("*h:mm [hours]");


    return (
        <>
            <H2>Shift Details</H2>
            <Details title="Shift (YYYY/MM/DD)" data={moment(shiftStart).format('YYYY/MM/DD')} />
            <Row>
                <Details title="Shift Start" data={moment(shiftStart).format('h:mm a')} />
                <Details title="Shift End" data={moment(shiftEnd).format('h:mm a')} />
                <Details />
            </Row>
            <Row>
                <Details title="Total Duration" data={shiftDuration} />
                <Details title="Updates" data={archive.length} />
                <Details title="Events" data={<Events />} />
            </Row>
            {eventHistory && <ModalEventHistory onClose={() => setEventHistory(false)} />}
        </>
    )
}

export default ArchiveDetails;