import moment from 'moment';

const checkInProtocolMap = {
    THIRTY: 30,
    SIXTY: 60,
    NINETY: 90,
    ONETWENTY: 120,
    TWOFORTY: 240,
};

const getNextCheckIn = (user) => {
    try {
        const { checkInProtocol, manualTimestamp } = user;
        const interval = checkInProtocolMap[checkInProtocol];
        const startTime = moment(manualTimestamp);
        let checkIn = startTime.add(interval, 'm');
        return checkIn;
    } catch (ex) {
        console.log("Failed to get next checkin", ex);
        return null;
    }
};

export default getNextCheckIn;
