import styled from "styled-components";
import Portal from "./Portal";
import theme from "../common/theme";

const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.7);
    z-index: 5;
    `;

const ModalBody = styled.div`
    display: flex;
    background-color: white; //${theme.palette.blue};
    color: black;
    width: ${props => props.size === 'lg' ? '90%' : '45%'};
    padding: 1rem;
    max-height: 95%;

    @media (max-width: 768px) {
        width: 90%;
        padding: 1rem;
    }
`;

const Modal = ({ show, children, onClose, size = 'lg', bodyStyle, containerStyle }) => {
    if (!show) {
        return null;
    }

    return (
        <Portal
            node={document.getElementsByTagName('body')[0]}
        >
            <ModalContainer onClick={onClose} style={containerStyle}>
                <ModalBody onClick={(e) => e.stopPropagation()} size={size} style={bodyStyle}>
                    {children}
                </ModalBody>
            </ModalContainer>
        </Portal>
    )
}

export default Modal