import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { roles } from "../common/constants"
import { listCompanies } from '../common/slices/company'
import { fetchCompanySchedules } from '../common/slices/schedule'
import { selectUser } from '../common/slices/user'
import theme from '../common/theme'
import TableCompany from "./TableCompany"
import TableOnline from "./TableOnline"
import TableShiftSchedule from './TableShiftSchedule'
import TableUser from "./TableUser"
import { EmptyTab, Tab, TabContainer, TabsContainer } from "./Tabs"
import { H1 } from './Typography'

const ScreenManageUsers = () => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    return (
        <>
            <TabContainer style={{ padding: '1rem' }}>
                <H1 style={{ fontWeight: 700, fontSize: '35px' }}>Admin</H1>
                <TabsContainer>
                    <Tab tabId={0}><FontAwesomeIcon icon={faCircle} color={theme.palette.green} style={{ fontSize: '0.5rem', lineHeight: '0.5rem' }} /> Online</Tab>
                    <Tab tabId={1}>Users</Tab>
                    {
                        user.role === roles.OPERATOR &&
                        <Tab tabId={2} onClick={() => dispatch(listCompanies(user.companyId, user.role))}>Companies</Tab>
                    }
                    {
                        (user.role === roles.OPERATOR || user.role === roles.SUPERVISOR) &&
                        <Tab tabId={3} onClick={() => dispatch(fetchCompanySchedules(user.companyId))}>Shift Schedule</Tab>
                    }
                    <EmptyTab />
                </TabsContainer>

                <TableOnline />
                <TableUser />
                <TableCompany />
                <TableShiftSchedule />
            </TabContainer>
        </>
    )
}

export default ScreenManageUsers;
