import { API } from 'aws-amplify'

export default class QueryBuilder {
    constructor(query, queryName, pageSize = 13) {
        this.query = query;
        this.queryName = queryName;
        this.pageSize = pageSize;
        this.nextToken = null;
        this.nextNextToken = null;
        this.previousTokens = [];
        this.params = {};
        this.filter = {};
    }

    setParams(params) {
        this.params = params;
    }

    setFilter(filter) {
        this.filter = filter;
    }

    async execute() {
        try {
            const resp = await API.graphql({
                query: this.query,
                variables: {
                    ...this.params,
                    limit: this.pageSize,
                    filter: this.filter,
                    nextToken: this.nextToken,
                }
            })
            const data = resp.data[this.queryName];
            this.nextNextToken = data.nextToken;
            return data;
        } catch (ex) {
            console.log("Failed to run query", this.queryName, ex);
        }
    }

    nextPage() {
        if (!this.nextNextToken) {
            throw new Error("Can't page when there is no page to page to");
        }

        if (this.nextToken) {
            this.previousTokens.push(this.nextToken);
        }
        this.nextToken = this.nextNextToken;
        return this.execute();
    }

    prevPage() {
        const token = this.previousTokens.pop();
        this.nextToken = token;
        return this.execute();
    }
}