import { faBattery, faCheck, faDashboard, faRefresh, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { maxBy, minBy } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { selectCompanyCheckIns, setActiveCheckIn } from "../common/slices/checkIn";
import theme from "../common/theme";
import isOnJourney from "../common/utils/isOnJourney";
import isOnShift from "../common/utils/isOnShift";
import { setModalState } from "../slices/app";
import { P } from "./Typography";

const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    span {
        color: ${theme.palette.green};
    }
`

const StatusTitle = styled(P)`
    color: rgba(187, 187, 187, 1);
    margin: 0;
    padding: 0;
`

const Status = ({ Icon, title, Data, ...props }) => (
    <StatusContainer {...props}>
        {Icon}
        <div>
            <StatusTitle>{title}</StatusTitle>
            {Data}
        </div>
    </StatusContainer>
)

const map = {
    THIRTY: 30,
    SIXTY: 60,
    NINETY: 90,
    ONETWENTY: 120,
    TWOFORTY: 240,
};


const MapInfo = () => {
    const dispatch = useDispatch();

    const onClickStatus = (checkIn) => () => {
        dispatch(setActiveCheckIn(checkIn.userId));
        dispatch(setModalState({ modalName: 'USER_DETAILS', show: true }))
    }

    const checkIns = useSelector(selectCompanyCheckIns);

    const onShift = checkIns.filter(c => isOnShift(c.status) || isOnJourney(c.status))
    const fastest = maxBy(onShift, c => c.location?.speed)
    const latest = maxBy(checkIns, c => c.updatedAt)
    const lowBattery = minBy(onShift.filter(c => c.battery?.batteryState === 1), c => c.battery?.batteryLevel || 1)
    const nextCheckIn = minBy(onShift, c => moment(c?.user?.manualTimestamp).add(map[c?.user?.checkInProtocol], 'minute').diff(moment(), 'minute'))

    const speed = ((fastest?.location?.speed || 0) * 3.6).toFixed(2);
    const battery = (lowBattery?.battery?.batteryLevel * 100).toFixed(0);

    return (
        <>
            <Status onClick={onClickStatus(fastest)} Icon={<FontAwesomeIcon icon={faDashboard} size="2x" />} title="Top speed of all people at the moment" Data={<div>{speed} km/h - <span>{`${fastest?.user?.firstName} ${fastest?.user?.lastName}`} - {fastest?.company?.name}</span></div>} />
            <Status onClick={onClickStatus(lowBattery)} Icon={<FontAwesomeIcon icon={faBattery} size="2x" />} title="Lowest battery not plugged in for all users on shift" Data={<div>{battery}% - <span>{lowBattery?.user?.firstName} {lowBattery?.user?.lastName} - {lowBattery?.company?.name}</span></div>} />
            <Status Icon={<FontAwesomeIcon icon={faUserGroup} size="2x" />} title="Total people on shift (% of total users being shown)" Data={<div>{onShift.length} - On shift ({((onShift.length / checkIns.length) * 100).toFixed(2)}%)</div>} />
            <Status onClick={onClickStatus(latest)} Icon={<FontAwesomeIcon icon={faRefresh} size="2x" />} title="Last person to send a ping" Data={<div><span>{`${latest?.user?.firstName} ${latest?.user?.lastName}`} - {latest?.company?.name}</span></div>} />
            <Status onClick={onClickStatus(nextCheckIn)} Icon={<FontAwesomeIcon icon={faCheck} size="2x" />} title="Next person to do a check in" Data={<div><span style={{ color: 'rgba(242, 180, 1, 1)' }}>{`${nextCheckIn?.user?.firstName} ${nextCheckIn?.user?.lastName}`} - {nextCheckIn?.company?.name}</span></div>} />
        </>
    )
}

export default MapInfo;