import { faBell, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import alertSound from '../common/assets/mixkit_positive_notification_951.wav';
import { selectNotifications, setActiveCheckIn } from "../common/slices/checkIn";
import theme from "../common/theme";
import frindlyStatus from '../common/utils/friendlyStatus';
import { selectModalState, setModalState } from "../slices/app";
import Avatar from "./Avatar";
import S3ImageCheckIn from "./S3ImageCheckIn";
import SideBar from "./SideBar";
import { H1, H2, P, P2 } from "./Typography";
import Row from './Row';
import { ButtonBase } from "./Buttons";

const statusMap = {
    "SHIFT_PANIC": "activated panic mode! Please take appropriate action immediately.",
    "SHIFT_NOT_RESPONDING": "missed a scheduled check-in.  Please take appropriate action immediately.",
    "JOURNEY_PANIC": "activated panic mode! Please take appropriate action immediately.",
}

const NotificationList = styled.div`
    margin-top: 2rem;
    `;

const ListItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    cursor: pointer;
    
    &:hover {
        background-color: ${theme.palette.border};
    }
`

const Empty = styled.div`
    padding: 0 1rem;
`

const SideBarNotifications = ({ user }) => {
    const show = useSelector(selectModalState("SIDEBAR"));
    const notifications = useSelector(selectNotifications)
    const dispatch = useDispatch()

    const onClickCheckIn = (checkIn) => {
        dispatch(setActiveCheckIn(checkIn.userId))
        dispatch(setModalState({ modalName: 'USER_DETAILS', show: true }))
    }

    useEffect(() => {
        document.title = notifications.length ? `Be Safe (${notifications.length}) - Work Alone` :
            'Be Safe - Work Alone';
    }, [notifications])

    useEffect(() => {
        const audio = new Audio(alertSound)
        if (notifications.length) {
            audio.play();
        }

        return () => {
            audio.pause();
        }
    }, [notifications.length])

    return (
        <SideBar
            show={show}
        >
            <Row style={{ borderBottom: `1px solid ${theme.palette.border}`, padding: '1rem' }}>
                <H1 style={{ flex: 1, marginRight: 'auto' }}>Notifications{notifications?.length ? ` (${notifications.length})` : ''}</H1>
                <ButtonBase onClick={() => dispatch(setModalState({ modalName: 'SIDEBAR', show: false }))}><FontAwesomeIcon icon={faTimesCircle} color="white" size="1x" /></ButtonBase>
            </Row>
            <NotificationList>
                {
                    !notifications?.length ?
                        <Empty>
                            <H2 noMargin>No Alerts at the Moment</H2>
                            <P2>Great job! All team members are safe and have completed their check-ins. Stay vigilant and ready to respond—this is where you'll see any missed check-ins, panic alerts, or other important notifications. Keep up the good work!</P2>
                        </Empty>
                        :
                        notifications.map(checkIn => (
                            <ListItem key={checkIn.userId} onClick={() => onClickCheckIn(checkIn)}>
                                <Row><P2 style={{ flex: 1, color: 'rgba(187, 187, 187, 1)' }}>{moment(checkIn.updatedAt).fromNow()}</P2><S3ImageCheckIn status={checkIn?.status} style={{ marginLeft: 'auto', flex: 0 }} /></Row>
                                <P noMargin><b>{frindlyStatus(checkIn.status)}</b></P>
                                <P noMargin>{`${checkIn?.user?.firstName} ${checkIn?.user?.lastName} with ${checkIn?.company?.name} has ${statusMap[checkIn?.status]}`}</P>
                            </ListItem>
                        ))
                }
            </NotificationList>
        </SideBar>
    )
}

export default SideBarNotifications