import { API } from "aws-amplify";
import { updateUser } from "../graphql/mutations";
import insertUser from "./insertUser";

export default async ({ createdAt, updatedAt, erpArea, company, checkIns, installations, archive, __typename, ...input }) => {
    try {
        const { data } = await API.graphql({
            query: updateUser,
            variables: {
                input
            },
        });
        return data.updateUser;
    } catch (ex) {
        try {
            return await insertUser({ ...input });
        } catch (ex) {
            console.error("Failed to update / insert user", ex);
            return false;
        }
    }
};
