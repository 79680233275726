import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectStatus, updateStatus } from "../common/slices/registration"
import theme from "../common/theme"
import { BlueButton, ButtonBase } from "./Buttons"
import Divider from "./Divider"
import Row from "./Row"
import { SREmailInput, SRPhoneNumber, SRTextInput } from "./SelfRegistrationComponents"
import { Container, Form } from "./SelfRegistrationLayout"
import { H1, P } from "./Typography"


export default () => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const status = useSelector(selectStatus);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting, isValidating },
    } = useForm({
        mode: 'onChange',
        values: status.accountOwner?.owner
    })

    const fieldProps = { control, errors, rules: { required: true } }

    const onNext = (values) => {
        dispatch(updateStatus({ step: "accountOwner", status: { done: true, owner: values } }))
        nav("/auth/registration/company")
    }

    return (
        <Container>
            <div>
                <H1>Account Owner</H1>
                <P>The account owner manages the companies account, oversees onboarding, will receive monthly invoices from Be Safe,
                    and ensures compliance with company policies and <ButtonBase style={{ color: theme.palette.lightBlue }} onClick={() => nav("/auth/registration/")}>Terms of Service</ButtonBase>.
                </P>
            </div>
            <Form>
                <Row>
                    <SRTextInput name="firstName" label="First Name" placeholder="Enter first name" {...fieldProps} />
                    <SRTextInput name="lastName" label="Last Name" placeholder="Enter last name" {...fieldProps} />
                </Row>
                <Row>
                    <SRPhoneNumber name="phoneNumber" label="Phone Number" placeholder="Enter phone number" {...fieldProps} />
                    <SREmailInput name="email" label="Email Address" placeholder="Enter email address" {...fieldProps} />
                </Row>
            </Form>
            <Divider style={{ marginTop: 'auto' }} />
            <BlueButton disabled={!isValid || isSubmitting || isValidating} onClick={handleSubmit(onNext)}>Save & Next</BlueButton>
        </Container>
    )
}
