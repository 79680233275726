import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectStatus, updateStatus } from "../common/slices/registration"
import { BlueButton } from "./Buttons"
import Divider from "./Divider"
import { FormWorkers } from "./SelfRegistrationComponents"
import { Container, Form } from "./SelfRegistrationLayout"
import { H1, P } from "./Typography"




export default () => {
    const nav = useNavigate();
    const dispatch = useDispatch();
    const status = useSelector(selectStatus);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting, isValidating }
    } = useForm({
        values: status?.workers?.workers
    })

    const fieldProps = { control, errors, rules: { required: true } }

    const onNext = (values) => {
        dispatch(updateStatus({ step: "workers", status: { done: true, workers: values } }))

        nav("/auth/registration/payment")
    }

    return (
        <Container>
            <div>
                <H1>Workers</H1>
                <P>Workers are the users who interact with the application daily and are assigned to specific ERP groups based on their roles.</P>
            </div>
            <Form style={{ maxWidth: 'none' }}>
                <FormWorkers {...fieldProps} />
            </Form>
            <Divider style={{ marginTop: 'auto' }} />
            <BlueButton disabled={!isValid || isSubmitting || isValidating} onClick={handleSubmit(onNext)}>Save & Next</BlueButton>
        </Container>
    )
}
