import ColorHash from 'color-hash';
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import theme from "../common/theme";
import { selectArchive } from '../slices/archive';

const ch = new ColorHash({
    lightness: [0.4, 0.6],
    saturation: [0.6, 0.9],
    hue: [30, 340]
})

const Polyline = ({ ...props }) => {
    useEffect(() => {
        const polyline = new window.google.maps.Polyline({
            geodesic: true,
            path: props.path,
            strokeColor: props.color,
            strokeOpacity: 1,
            strokeWeight: props.weight,
        })
        polyline.setMap(props.map)

        return () => {
            polyline.setMap(null);
        }
    }, [props.path]);
}

const PolylineArchive = ({ map, archive }) => {
    const path = archive.map(point => point.location);
    const color = ch.hex(JSON.stringify(path))

    return [
        <Polyline
            key={`archive-1`}
            path={path}
            color={theme.palette.blue}
            map={map}
            weight={6}
        />,
        <Polyline
            key={`archive-2`}
            path={path}
            color={color}
            map={map}
            weight={4}
        />
    ];
}

export default PolylineArchive;