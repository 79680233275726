import { createSelector, createSlice } from "@reduxjs/toolkit";
import { roles } from "../constants";
import service from "../service";
import { reset } from "./globalActions";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as customQueries from "../graphql/customQueries";
import { createEmergencyContact, createUserTermsAcceptance, updateContacts } from "../graphql/mutations";
import QueryBuilder from "../service/QueryBuilder";

const listUsersQuery = new QueryBuilder(customQueries.listUsers, "listUsers")
const listUsersByCompanyIdQuery = new QueryBuilder(customQueries.usersByCompanyIdAndStatus, "usersByCompanyIdAndStatus")
let subscription;

const initialState = {
    initialized: false,
    loading: false,
    user: false,
    userSubscription: null,
    userSubscriptionError: null,
    authUser: false,
    active: null,
    list: [],
    page: 0,
    pageTokens: [],
    contacts: []
};

const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(reset, (state) => {
            subscription?.unsubscribe();
            subscription = undefined;
            return {
                ...initialState,
                initialized: state.initialized,
            };
        });
    },
    reducers: {
        setInitialized: (state, action) => {
            state.initialized = true;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setUser: (state, action) => {
            state.loading = false;
            state.user = action.payload || false;
        },
        setAuthUser: (state, action) => {
            state.authUser = action.payload;
        },
        setActiveUser: (state, action) => {
            state.active = action.payload;
        },
        resetUserData: (state) => {
            state.user = false;
            state.authUser = false;
        },
        setUserSubscription: (state, action) => {
            state.userSubscription = action.payload;
        },
        setUserList: (state, action) => {
            state.list = action.payload;
        },
        addPageToken: (state, action) => {
            if (action.payload && state.page === state.pageTokens.length) {
                state.pageTokens.push(action.payload);
            } else if (state.pageTokens[state.page]) {
                state.pageTokens[state.page] = action.payload;
            }
        },
        resetPagination: (state) => {
            state.pageTokens = [];
            state.page = 0;
        },
        setPrevPageToken: (state, action) => {
            state.prevPageToken = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        onUpdateUser: (state, action) => {
            let userIndex = state.list.findIndex((u) => u.email === action.payload.email);
            if (userIndex >= 0) state.list[userIndex] = action.payload;
            else state.list.push(action.payload);
            if (action.payload.email === state.user.email) {
                state.user = action.payload;
            }
        },
        onInsertUser: (state, action) => {
            if (action.payload) {
                state.list.push(action.payload);
            }
        },
        onDeleteUser: (state, action) => {
            let userIndex = state.list.findIndex((u) => u.email === action.payload.email);
            state.list = [...state.list.slice(0, userIndex), ...state.list.slice(userIndex + 1)];
        },
        setContacts: (state, action) => {
            state.contacts = action.payload
        },
        onCreateUserEmergencyContact: (state, action) => {
            state.contacts.push(action.payload);
        }
    },
});

export const {
    addPageToken,
    resetPagination,
    resetUserData,
    setInitialized,
    setContacts,
    setLoading,
    setUser,
    setAuthUser,
    setUserList,
    setUserSubscription,
    setActiveUser,
    setPrevPageToken,
    setPage,
    onUpdateUser,
    onInsertUser,
    onDeleteUser,
    onCreateUserEmergencyContact
} = userSlice.actions;
export default userSlice.reducer;

export const selectState = (state) => state.user;
export const selectUser = createSelector(selectState, (state) => state.user);
export const selectAuthUser = createSelector(selectState, (state) => state.authUser);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectInitialized = createSelector(selectState, (state) => state.initialized);
export const selectUserSubscription = createSelector(selectState, (state) => state.userSubscription);
export const selectUserEmergencyContacts = createSelector(selectState, (state) => state.contacts);
export const selectUserList = createSelector(selectState, (state) => state.list);
export const selectUserPagination = createSelector(selectState, (state) => ({
    pageTokens: state.pageTokens,
    page: state.page,
}));
export const selectActiveUser = createSelector(selectState, (state) => {
    return state.list.find((u) => u.email === state.active);
});
export const selectIsSupervisor = (state) => {
    const user = selectUser(state);
    return user.role === roles.SUPERVISOR;
};
export const selectIsRemoteWorker = (state) => {
    const user = selectUser(state);
    return user.role === roles.REMOTE_WORKER;
};

export const subscribeUserUpdates =
    (email, retry = 0) =>
        async (dispatch, getState) => {
            try {
                if (selectUser(getState()) && !subscription) {
                    const next = ({ value }) => {
                        try {
                            const { data } = value;
                            dispatch(setUser(data.onUpdateUser));
                        } catch (ex) {
                            console.error("failed to update user reducer", ex);
                        }
                    };
                    if (subscription) subscription.unsubscribe();
                    subscription = await service.subscribeUserUpdates(email, next, async (error) => {
                        console.error("user subscription failure", error);
                    });
                    dispatch(setUserSubscription(subscription));
                    return subscription;
                }
            } catch (ex) {
                console.log("failed to subscribe to user updates", ex);
            }
        };

export const unsubscribeUserUpdates = (dispatch, getState) => {
    try {
        selectUserSubscription(getState())?.unsubscribe();
        dispatch(setUserSubscription(null));
    } catch (ex) {
        console.log("failed to unsubscribe", ex);
    }
};

export const refreshUserData = async (dispatch) => {
    const authUser = await dispatch(getAuthUser);
    if (authUser) {
        return await dispatch(getUser(authUser?.attributes?.email));
    }
};

export const getAuthUser = async (dispatch) => {
    const authUser = await service.getAuthUser();
    if (authUser) {
        dispatch(setAuthUser(authUser));
        return authUser;
    }
};

export const getUser = (email) => async (dispatch) => {
    const user = await service.getUser(email);
    if (user) {
        dispatch(setUser(user));
    }
    return user;
};

export const listUsers = (companyId, role) => async (dispatch) => {
    let users;
    dispatch(resetPagination());
    if (role === roles.OPERATOR) {
        users = await listUsersQuery.execute();
        dispatch(setUserList(users.items));
    } else if (companyId) {
        listUsersByCompanyIdQuery.setParams({ companyId })
        users = await listUsersByCompanyIdQuery.execute();
        dispatch(setUserList(users.items));
    }
    return users;
};

export const searchUsers = (search, companyId, role) => async (dispatch) => {
    let users;
    dispatch(resetPagination());
    if (role === roles.OPERATOR) {
        users = await service.searchUsers(search);
        dispatch(setUserList(users.items));
    } else if (companyId) {
        users = await service.searchUsers(search, companyId);
        dispatch(setUserList(users.items));
    }
    return users;
};

export const nextUserPage = (companyId, role) => async (dispatch, getState) => {
    let users;
    if (role === roles.OPERATOR) {
        users = await listUsersQuery.nextPage();
    } else if (companyId) {
        users = await listUsersByCompanyIdQuery.nextPage();
    }
    dispatch(setUserList(users.items));
    return users;
};

export const prevUserPage = (companyId, role) => async (dispatch, getState) => {
    let users;
    if (role === roles.OPERATOR) {
        users = await listUsersQuery.prevPage();
    } else if (companyId) {
        users = await listUsersByCompanyIdQuery.prevPage();
    }
    dispatch(setUserList(users.items));
    return users;
};

export const updateUser = (updates) => async (dispatch) => {
    const updatedUser = await service.updateUser(updates);
    dispatch(onUpdateUser(updatedUser));
};

export const deleteUser = (user) => async (dispatch) => {
    await service.deleteUser(user);
    dispatch(onDeleteUser(user));
};

export const fetchUserEmergencyContacts = (email) => async (dispatch) => {
    try {
        const response = await API.graphql({
            query: queries.emergencyContactsByUserId,
            variables: {
                userId: email
            }
        })
        dispatch(setContacts(response.data.emergencyContactsByUserId.items));
    } catch (ex) {
        console.log("failed to get user emergency contacts", ex);
    }
}

export const createUserEmergencyContacts = (email, contacts) => async (dispatch) => {
    try {
        const response = await API.graphql({
            query: updateContacts,
            variables: {
                input: {
                    type: "INDIVIDUAL",
                    contacts: contacts.map(c => ({ ...c, userId: email }))
                }
            }
        })

        dispatch(setContacts(response.data.updateContacts))
    } catch (ex) {
        console.log("Failed to create user contacts", ex);
    }
}

export const fetchUserTermsAcceptance = (email, version) => async (dispatch) => {
    try {
        const response = await API.graphql({
            query: queries.getUserTermsAcceptance,
            variables: {
                email,
                version
            }
        })

        return response.data.getUserTermsAcceptance
    } catch (ex) {
        console.log('ex', ex);
    }
}

export const acceptTerms = (email, version = "1.0") => async (dispatch) => {
    try {
        const response = await API.graphql({
            query: createUserTermsAcceptance,
            variables: {
                input: {
                    email,
                    version,
                    acceptedAt: new Date().toISOString(),
                    status: "ACCEPTED",

                }
            }
        })

        return response.data.createUserTermsAcceptance
    } catch (ex) {
        console.log('ex', ex);
    }
}