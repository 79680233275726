/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNewUser = /* GraphQL */ `
  mutation CreateNewUser($input: CreateNewUserInput) {
    createNewUser(input: $input) {
      email
      firstName
      lastName
      role
      avatar
      status
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        customerId
        subscriptionStatus
        subscriptionStatusUpdated
        createdAt
        updatedAt
        __typename
      }
      checkIns {
        nextToken
        __typename
      }
      checkInProtocol
      shiftStart
      nextCheckIn
      installations {
        nextToken
        __typename
      }
      archive {
        nextToken
        __typename
      }
      phoneNumber
      contactNotes
      manualTimestamp
      satelliteDeviceId
      satelliteDeviceType
      satelliteDeviceSMS
      mode
      erpAreaId
      erpArea {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      excludeShiftReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const customDeleteUser = /* GraphQL */ `
  mutation CustomDeleteUser($input: DeleteUserInput) {
    customDeleteUser(input: $input)
  }
`;
export const customDeleteCompany = /* GraphQL */ `
  mutation CustomDeleteCompany($input: DeleteCompanyInput) {
    customDeleteCompany(input: $input)
  }
`;
export const setUserPassword = /* GraphQL */ `
  mutation SetUserPassword($input: SetUserPasswordInput) {
    setUserPassword(input: $input)
  }
`;
export const updateContacts = /* GraphQL */ `
  mutation UpdateContacts($input: UpdateContactsInput) {
    updateContacts(input: $input) {
      id
      priority
      name
      email
      phone
      notifyBySMS
      notifyByEmail
      notificationTypes
      type
      companyId
      erpAreaId
      erpArea {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWholeCompany = /* GraphQL */ `
  mutation UpdateWholeCompany($input: UpdateWholeCompanyInput) {
    updateWholeCompany(input: $input)
  }
`;
export const createArchive = /* GraphQL */ `
  mutation CreateArchive(
    $input: CreateArchiveInput!
    $condition: ModelArchiveConditionInput
  ) {
    createArchive(input: $input, condition: $condition) {
      email
      date
      locationHistory {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateArchive = /* GraphQL */ `
  mutation UpdateArchive(
    $input: UpdateArchiveInput!
    $condition: ModelArchiveConditionInput
  ) {
    updateArchive(input: $input, condition: $condition) {
      email
      date
      locationHistory {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteArchive = /* GraphQL */ `
  mutation DeleteArchive(
    $input: DeleteArchiveInput!
    $condition: ModelArchiveConditionInput
  ) {
    deleteArchive(input: $input, condition: $condition) {
      email
      date
      locationHistory {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCheckIn = /* GraphQL */ `
  mutation CreateCheckIn(
    $input: CreateCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    createCheckIn(input: $input, condition: $condition) {
      userId
      timestamp
      location {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      battery {
        batteryLevel
        batteryState
        lowPowerMode
        __typename
      }
      status
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        customerId
        subscriptionStatus
        subscriptionStatusUpdated
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCheckIn = /* GraphQL */ `
  mutation UpdateCheckIn(
    $input: UpdateCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    updateCheckIn(input: $input, condition: $condition) {
      userId
      timestamp
      location {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      battery {
        batteryLevel
        batteryState
        lowPowerMode
        __typename
      }
      status
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        customerId
        subscriptionStatus
        subscriptionStatusUpdated
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCheckIn = /* GraphQL */ `
  mutation DeleteCheckIn(
    $input: DeleteCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    deleteCheckIn(input: $input, condition: $condition) {
      userId
      timestamp
      location {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      battery {
        batteryLevel
        batteryState
        lowPowerMode
        __typename
      }
      status
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        customerId
        subscriptionStatus
        subscriptionStatusUpdated
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCheckInArchive = /* GraphQL */ `
  mutation CreateCheckInArchive(
    $input: CreateCheckInArchiveInput!
    $condition: ModelCheckInArchiveConditionInput
  ) {
    createCheckInArchive(input: $input, condition: $condition) {
      email
      timestamp
      location {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      status
      battery {
        batteryLevel
        batteryState
        lowPowerMode
        __typename
      }
      companyId
      __typename
    }
  }
`;
export const updateCheckInArchive = /* GraphQL */ `
  mutation UpdateCheckInArchive(
    $input: UpdateCheckInArchiveInput!
    $condition: ModelCheckInArchiveConditionInput
  ) {
    updateCheckInArchive(input: $input, condition: $condition) {
      email
      timestamp
      location {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      status
      battery {
        batteryLevel
        batteryState
        lowPowerMode
        __typename
      }
      companyId
      __typename
    }
  }
`;
export const deleteCheckInArchive = /* GraphQL */ `
  mutation DeleteCheckInArchive(
    $input: DeleteCheckInArchiveInput!
    $condition: ModelCheckInArchiveConditionInput
  ) {
    deleteCheckInArchive(input: $input, condition: $condition) {
      email
      timestamp
      location {
        lat
        lng
        heading
        speed
        altitude
        __typename
      }
      status
      battery {
        batteryLevel
        batteryState
        lowPowerMode
        __typename
      }
      companyId
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      logo
      emergencyResponsePhoneNumber
      contactNotes
      erpAreas {
        nextToken
        __typename
      }
      utcOffset
      customerId
      subscriptionStatus
      subscriptionStatusUpdated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      logo
      emergencyResponsePhoneNumber
      contactNotes
      erpAreas {
        nextToken
        __typename
      }
      utcOffset
      customerId
      subscriptionStatus
      subscriptionStatusUpdated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      logo
      emergencyResponsePhoneNumber
      contactNotes
      erpAreas {
        nextToken
        __typename
      }
      utcOffset
      customerId
      subscriptionStatus
      subscriptionStatusUpdated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createERPArea = /* GraphQL */ `
  mutation CreateERPArea(
    $input: CreateERPAreaInput!
    $condition: ModelERPAreaConditionInput
  ) {
    createERPArea(input: $input, condition: $condition) {
      id
      name
      contactInfo
      contactNotes
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        customerId
        subscriptionStatus
        subscriptionStatusUpdated
        createdAt
        updatedAt
        __typename
      }
      users {
        nextToken
        __typename
      }
      emergencyContacts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateERPArea = /* GraphQL */ `
  mutation UpdateERPArea(
    $input: UpdateERPAreaInput!
    $condition: ModelERPAreaConditionInput
  ) {
    updateERPArea(input: $input, condition: $condition) {
      id
      name
      contactInfo
      contactNotes
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        customerId
        subscriptionStatus
        subscriptionStatusUpdated
        createdAt
        updatedAt
        __typename
      }
      users {
        nextToken
        __typename
      }
      emergencyContacts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteERPArea = /* GraphQL */ `
  mutation DeleteERPArea(
    $input: DeleteERPAreaInput!
    $condition: ModelERPAreaConditionInput
  ) {
    deleteERPArea(input: $input, condition: $condition) {
      id
      name
      contactInfo
      contactNotes
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        customerId
        subscriptionStatus
        subscriptionStatusUpdated
        createdAt
        updatedAt
        __typename
      }
      users {
        nextToken
        __typename
      }
      emergencyContacts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmergencyContact = /* GraphQL */ `
  mutation CreateEmergencyContact(
    $input: CreateEmergencyContactInput!
    $condition: ModelEmergencyContactConditionInput
  ) {
    createEmergencyContact(input: $input, condition: $condition) {
      id
      priority
      name
      email
      phone
      notifyBySMS
      notifyByEmail
      notificationTypes
      type
      companyId
      erpAreaId
      erpArea {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmergencyContact = /* GraphQL */ `
  mutation UpdateEmergencyContact(
    $input: UpdateEmergencyContactInput!
    $condition: ModelEmergencyContactConditionInput
  ) {
    updateEmergencyContact(input: $input, condition: $condition) {
      id
      priority
      name
      email
      phone
      notifyBySMS
      notifyByEmail
      notificationTypes
      type
      companyId
      erpAreaId
      erpArea {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmergencyContact = /* GraphQL */ `
  mutation DeleteEmergencyContact(
    $input: DeleteEmergencyContactInput!
    $condition: ModelEmergencyContactConditionInput
  ) {
    deleteEmergencyContact(input: $input, condition: $condition) {
      id
      priority
      name
      email
      phone
      notifyBySMS
      notifyByEmail
      notificationTypes
      type
      companyId
      erpAreaId
      erpArea {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createIndividualShift = /* GraphQL */ `
  mutation CreateIndividualShift(
    $input: CreateIndividualShiftInput!
    $condition: ModelIndividualShiftConditionInput
  ) {
    createIndividualShift(input: $input, condition: $condition) {
      id
      email
      scheduleId
      expiry
      name
      description
      shiftDate
      startTime
      endTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIndividualShift = /* GraphQL */ `
  mutation UpdateIndividualShift(
    $input: UpdateIndividualShiftInput!
    $condition: ModelIndividualShiftConditionInput
  ) {
    updateIndividualShift(input: $input, condition: $condition) {
      id
      email
      scheduleId
      expiry
      name
      description
      shiftDate
      startTime
      endTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIndividualShift = /* GraphQL */ `
  mutation DeleteIndividualShift(
    $input: DeleteIndividualShiftInput!
    $condition: ModelIndividualShiftConditionInput
  ) {
    deleteIndividualShift(input: $input, condition: $condition) {
      id
      email
      scheduleId
      expiry
      name
      description
      shiftDate
      startTime
      endTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInstallation = /* GraphQL */ `
  mutation CreateInstallation(
    $input: CreateInstallationInput!
    $condition: ModelInstallationConditionInput
  ) {
    createInstallation(input: $input, condition: $condition) {
      owner
      id
      deviceName
      expoPushToken
      appVersion
      brand
      modelName
      modelId
      productName
      totalMemory
      osName
      osVersion
      osBuildId
      __typename
    }
  }
`;
export const updateInstallation = /* GraphQL */ `
  mutation UpdateInstallation(
    $input: UpdateInstallationInput!
    $condition: ModelInstallationConditionInput
  ) {
    updateInstallation(input: $input, condition: $condition) {
      owner
      id
      deviceName
      expoPushToken
      appVersion
      brand
      modelName
      modelId
      productName
      totalMemory
      osName
      osVersion
      osBuildId
      __typename
    }
  }
`;
export const deleteInstallation = /* GraphQL */ `
  mutation DeleteInstallation(
    $input: DeleteInstallationInput!
    $condition: ModelInstallationConditionInput
  ) {
    deleteInstallation(input: $input, condition: $condition) {
      owner
      id
      deviceName
      expoPushToken
      appVersion
      brand
      modelName
      modelId
      productName
      totalMemory
      osName
      osVersion
      osBuildId
      __typename
    }
  }
`;
export const createJourney = /* GraphQL */ `
  mutation CreateJourney(
    $input: CreateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    createJourney(input: $input, condition: $condition) {
      id
      startAddress
      endAddress
      eta
      route {
        waypoint_order
        overview_polyline
        warnings
        __typename
      }
      riskLevel
      userId
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateJourney = /* GraphQL */ `
  mutation UpdateJourney(
    $input: UpdateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    updateJourney(input: $input, condition: $condition) {
      id
      startAddress
      endAddress
      eta
      route {
        waypoint_order
        overview_polyline
        warnings
        __typename
      }
      riskLevel
      userId
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteJourney = /* GraphQL */ `
  mutation DeleteJourney(
    $input: DeleteJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    deleteJourney(input: $input, condition: $condition) {
      id
      startAddress
      endAddress
      eta
      route {
        waypoint_order
        overview_polyline
        warnings
        __typename
      }
      riskLevel
      userId
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMissedShift = /* GraphQL */ `
  mutation CreateMissedShift(
    $input: CreateMissedShiftInput!
    $condition: ModelMissedShiftConditionInput
  ) {
    createMissedShift(input: $input, condition: $condition) {
      shiftId
      email
      shiftDate
      shift {
        id
        email
        scheduleId
        expiry
        name
        description
        shiftDate
        startTime
        endTime
        __typename
        shiftPattern
        startDate
        endDate
        __typename
      }
      status
      createdAt
      __typename
    }
  }
`;
export const updateMissedShift = /* GraphQL */ `
  mutation UpdateMissedShift(
    $input: UpdateMissedShiftInput!
    $condition: ModelMissedShiftConditionInput
  ) {
    updateMissedShift(input: $input, condition: $condition) {
      shiftId
      email
      shiftDate
      shift {
        id
        email
        scheduleId
        expiry
        name
        description
        shiftDate
        startTime
        endTime
        __typename
        shiftPattern
        startDate
        endDate
        __typename
      }
      status
      createdAt
      __typename
    }
  }
`;
export const deleteMissedShift = /* GraphQL */ `
  mutation DeleteMissedShift(
    $input: DeleteMissedShiftInput!
    $condition: ModelMissedShiftConditionInput
  ) {
    deleteMissedShift(input: $input, condition: $condition) {
      shiftId
      email
      shiftDate
      shift {
        id
        email
        scheduleId
        expiry
        name
        description
        shiftDate
        startTime
        endTime
        __typename
        shiftPattern
        startDate
        endDate
        __typename
      }
      status
      createdAt
      __typename
    }
  }
`;
export const createRecurringShiftPattern = /* GraphQL */ `
  mutation CreateRecurringShiftPattern(
    $input: CreateRecurringShiftPatternInput!
    $condition: ModelRecurringShiftPatternConditionInput
  ) {
    createRecurringShiftPattern(input: $input, condition: $condition) {
      id
      email
      scheduleId
      name
      description
      expiry
      startTime
      endTime
      startDate
      endDate
      shiftPattern
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRecurringShiftPattern = /* GraphQL */ `
  mutation UpdateRecurringShiftPattern(
    $input: UpdateRecurringShiftPatternInput!
    $condition: ModelRecurringShiftPatternConditionInput
  ) {
    updateRecurringShiftPattern(input: $input, condition: $condition) {
      id
      email
      scheduleId
      name
      description
      expiry
      startTime
      endTime
      startDate
      endDate
      shiftPattern
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRecurringShiftPattern = /* GraphQL */ `
  mutation DeleteRecurringShiftPattern(
    $input: DeleteRecurringShiftPatternInput!
    $condition: ModelRecurringShiftPatternConditionInput
  ) {
    deleteRecurringShiftPattern(input: $input, condition: $condition) {
      id
      email
      scheduleId
      name
      description
      expiry
      startTime
      endTime
      startDate
      endDate
      shiftPattern
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShiftSchedule = /* GraphQL */ `
  mutation CreateShiftSchedule(
    $input: CreateShiftScheduleInput!
    $condition: ModelShiftScheduleConditionInput
  ) {
    createShiftSchedule(input: $input, condition: $condition) {
      id
      email
      companyId
      name
      description
      expiry
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      recurringShiftPatterns {
        nextToken
        __typename
      }
      individualShifts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShiftSchedule = /* GraphQL */ `
  mutation UpdateShiftSchedule(
    $input: UpdateShiftScheduleInput!
    $condition: ModelShiftScheduleConditionInput
  ) {
    updateShiftSchedule(input: $input, condition: $condition) {
      id
      email
      companyId
      name
      description
      expiry
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      recurringShiftPatterns {
        nextToken
        __typename
      }
      individualShifts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShiftSchedule = /* GraphQL */ `
  mutation DeleteShiftSchedule(
    $input: DeleteShiftScheduleInput!
    $condition: ModelShiftScheduleConditionInput
  ) {
    deleteShiftSchedule(input: $input, condition: $condition) {
      id
      email
      companyId
      name
      description
      expiry
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      recurringShiftPatterns {
        nextToken
        __typename
      }
      individualShifts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      role
      avatar
      status
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        customerId
        subscriptionStatus
        subscriptionStatusUpdated
        createdAt
        updatedAt
        __typename
      }
      checkIns {
        nextToken
        __typename
      }
      checkInProtocol
      shiftStart
      nextCheckIn
      installations {
        nextToken
        __typename
      }
      archive {
        nextToken
        __typename
      }
      phoneNumber
      contactNotes
      manualTimestamp
      satelliteDeviceId
      satelliteDeviceType
      satelliteDeviceSMS
      mode
      erpAreaId
      erpArea {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      excludeShiftReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      role
      avatar
      status
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        customerId
        subscriptionStatus
        subscriptionStatusUpdated
        createdAt
        updatedAt
        __typename
      }
      checkIns {
        nextToken
        __typename
      }
      checkInProtocol
      shiftStart
      nextCheckIn
      installations {
        nextToken
        __typename
      }
      archive {
        nextToken
        __typename
      }
      phoneNumber
      contactNotes
      manualTimestamp
      satelliteDeviceId
      satelliteDeviceType
      satelliteDeviceSMS
      mode
      erpAreaId
      erpArea {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      excludeShiftReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      email
      firstName
      lastName
      role
      avatar
      status
      companyId
      company {
        id
        name
        logo
        emergencyResponsePhoneNumber
        contactNotes
        utcOffset
        customerId
        subscriptionStatus
        subscriptionStatusUpdated
        createdAt
        updatedAt
        __typename
      }
      checkIns {
        nextToken
        __typename
      }
      checkInProtocol
      shiftStart
      nextCheckIn
      installations {
        nextToken
        __typename
      }
      archive {
        nextToken
        __typename
      }
      phoneNumber
      contactNotes
      manualTimestamp
      satelliteDeviceId
      satelliteDeviceType
      satelliteDeviceSMS
      mode
      erpAreaId
      erpArea {
        id
        name
        contactInfo
        contactNotes
        companyId
        createdAt
        updatedAt
        __typename
      }
      excludeShiftReport
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserSettings = /* GraphQL */ `
  mutation CreateUserSettings(
    $input: CreateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    createUserSettings(input: $input, condition: $condition) {
      email
      name
      value
      __typename
    }
  }
`;
export const updateUserSettings = /* GraphQL */ `
  mutation UpdateUserSettings(
    $input: UpdateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    updateUserSettings(input: $input, condition: $condition) {
      email
      name
      value
      __typename
    }
  }
`;
export const deleteUserSettings = /* GraphQL */ `
  mutation DeleteUserSettings(
    $input: DeleteUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    deleteUserSettings(input: $input, condition: $condition) {
      email
      name
      value
      __typename
    }
  }
`;
export const createUserTermsAcceptance = /* GraphQL */ `
  mutation CreateUserTermsAcceptance(
    $input: CreateUserTermsAcceptanceInput!
    $condition: ModelUserTermsAcceptanceConditionInput
  ) {
    createUserTermsAcceptance(input: $input, condition: $condition) {
      email
      companyId
      version
      acceptedAt
      status
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserTermsAcceptance = /* GraphQL */ `
  mutation UpdateUserTermsAcceptance(
    $input: UpdateUserTermsAcceptanceInput!
    $condition: ModelUserTermsAcceptanceConditionInput
  ) {
    updateUserTermsAcceptance(input: $input, condition: $condition) {
      email
      companyId
      version
      acceptedAt
      status
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserTermsAcceptance = /* GraphQL */ `
  mutation DeleteUserTermsAcceptance(
    $input: DeleteUserTermsAcceptanceInput!
    $condition: ModelUserTermsAcceptanceConditionInput
  ) {
    deleteUserTermsAcceptance(input: $input, condition: $condition) {
      email
      companyId
      version
      acceptedAt
      status
      user {
        email
        firstName
        lastName
        role
        avatar
        status
        companyId
        checkInProtocol
        shiftStart
        nextCheckIn
        phoneNumber
        contactNotes
        manualTimestamp
        satelliteDeviceId
        satelliteDeviceType
        satelliteDeviceSMS
        mode
        erpAreaId
        excludeShiftReport
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const setupPayment = /* GraphQL */ `
  mutation SetupPayment($input: SetupPaymentInput) {
    setupPayment(input: $input) {
      customerId
      clientSecret
      discountApplied
      __typename
    }
  }
`;
export const register = /* GraphQL */ `
  mutation Register($input: RegisterInput) {
    register(input: $input) {
      error
      success
      __typename
    }
  }
`;
