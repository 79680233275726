import { API } from "aws-amplify";
import { setUserPassword } from "../common/graphql/mutations";

export default async ({ username, password, permanent }) => {
    try {
        const {
            data: { setUserPassword: result },
        } = await API.graphql({
            query: setUserPassword,
            variables: {
                input: {
                    username,
                    password,
                    permanent,
                },
            },
        });
        return result;
    } catch (ex) {
        console.log("set user password failed", ex);
        return false;
    }
};
