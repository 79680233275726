import styled from "styled-components";
import theme from "../common/theme";

const ProgressBar = styled.div`
    width: 100%;
    height: ${props => props.height || '1rem'};
    background: rgba(41, 44, 84, 1);
    // border: 1px solid ${theme.palette.blue};
    padding: 1px;
    overflow: hidden;
    margin: 0.3rem 0;
`

const Progress = styled.div`
    position: relative;
    height: 100%;
    width: ${props => props.progress}%;
    background: ${props => props.alarm ? theme.palette.yellow : theme.palette.green};
    transition: width 1s ease-in-out;
`

export default ({ progress, height, ...rest }) => {
    return (<ProgressBar height={height} {...rest} >
        <Progress progress={progress} {...rest} />
    </ProgressBar>)
};