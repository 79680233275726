import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import logo from '../common/assets/feature_image.jpeg';
import { forgotPassword, forgotPasswordSubmit, getTOTPCode, login } from '../common/slices/auth';
import { Container, FormContainer, Input, LoginContainer, Logo } from './Auth';
import { ButtonBase, DarkButton } from './Buttons';
import { Footer } from './Layout';
import { H1 } from './Typography';


const ScreenForgotPasswordConfirm = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const location = useLocation();


    const onSubmit = async (values) => {
        if(values.password === values.password2) {
            await dispatch(forgotPasswordSubmit(values.email, values.code, values.password));
            await dispatch(login(values.email, values.password));
            nav('/');
        } else {
            setError("password", { message: "Passwords must match" })
            setError("password2", { message: "Passwords must match" })
        }
    }

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting, isValid, isDirty },
        watch,
    } = useForm({
        values: {
            email: location?.state?.email
        }
    })

    const fieldProps = { control, errors };

    return (
        <Container>
            <Logo src={logo} />
            <LoginContainer>
                <FormContainer>
                    <H1>Forgot Your Password</H1>
                    <Input name="email" placeholder="Username" {...fieldProps} />
                    <Input name="code" placeholder="Confirmation Code" {...fieldProps} />
                    <Input name="password" placeholder="Password" {...fieldProps} type="password" />
                    <Input name="password2" placeholder="Retype Password" {...fieldProps} type="password" />
                    <DarkButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting} >Set Password</DarkButton>
                    <ButtonBase style={{ color: 'white' }} onClick={() => nav('/auth/login')}>Back to Login</ButtonBase>
                </FormContainer>
            </LoginContainer>
            <Footer />
        </Container>
    )
}

export default ScreenForgotPasswordConfirm;
