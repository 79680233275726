import { configureStore } from "@reduxjs/toolkit";
import user from "./common/slices/user";
import checkIn from "./common/slices/checkIn";
import company from "./common/slices/company";
import schedule from "./common/slices/schedule";
import auth from "./common/slices/auth";
import app from "./slices/app";
import archive from "./slices/archive";
import report from "./slices/report";
import registration from './common/slices/registration'
import thunkMiddleware from "redux-thunk";

export default configureStore({
    reducer: {
        app,
        auth,
        archive,
        user,
        checkIn,
        company,
        report,
        schedule,
        registration,
    },
    middleware: [
        thunkMiddleware,
    ],
});