import FormMultiSelect from './FormMultiSelect';

export const NOTIFICATION_TYPE_MAP = {
    SHIFT_ACCIDENT: "Shift Accident",
    SHIFT_PANIC: "Shift Panic",
    SHIFT_NOT_RESPONDING: "Missed Check-in",
    JOURNEY_PANIC: "Journey Panic",
    JOURNEY_ACCIDENT: "Journey Accident",
    JOURNEY_NOT_RESPONDING: "Journey Missed Check-in",
}

export default ({ ...rest }) => {
    return (
        <FormMultiSelect {...rest} options={[
            { value: "SHIFT_ACCIDENT", label: "Shift Accident" },
            { value: "SHIFT_PANIC", label: "Shift Panic" },
            { value: "SHIFT_NOT_RESPONDING", label: "Missed Check-in" },
            { value: "JOURNEY_ACCIDENT", label: "Journey Accident" },
            { value: "JOURNEY_PANIC", label: "Journey Panic" },
            { value: "JOURNEY_NOT_RESPONDING", label: "Journey Missed Check-in" },
        ]} />
    );
};
