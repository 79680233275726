import { faCircle, faClock, faRecycle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import reduceSchedules from "../common/service/reduceSchedules";
import { fetchUserSchedules, selectSchedules } from "../common/slices/schedule";
import { selectActiveUser } from "../common/slices/user";
import theme from '../common/theme';
import { getColor } from "../service/colors";
import { ButtonBase, RedButton } from "./Buttons";
import Modal from './Modal';
import ModalAddSchedule from "./ModalAddSchedule";
import ModalScheduleEventDetails from "./ModalScheduleEventDetails";
import Row from './Row';
import { H1, P, P2 } from "./Typography";

const localizer = momentLocalizer(moment) // or globalizeLocalizer

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`

const Footer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid ${theme.palette.border}
`

const Empty = styled.div`
    flex: 1;
    text-align: center;
`;

const utcOffset = moment().local().utcOffset();

const ModalShiftSchedule = ({ show, onClose }) => {
    const [eventDetails, setShowEventDetails] = useState(false);
    const [newSchedule, setShowNewSchedule] = useState(false);
    const [loading, setLoading] = useState(true);
    const activeUser = useSelector(selectActiveUser);
    const schedules = useSelector(selectSchedules);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (activeUser) {
                setLoading(true);
                await dispatch(fetchUserSchedules(activeUser?.email));
                setLoading(false);
            }
        })()
    }, [activeUser])

    const eventPropGetter = (event) => {
        const color = getColor(event.id);
        return {
            style: {
                background: color,
                border: `1px solid ${color}`,
            }
        }
    }

    const onSelectSlot = async (slotDetails) => {
        setShowEventDetails(slotDetails);
    }

    const onSelectEvent = (event) => {
        setShowEventDetails(event);
    }

    const events = schedules.reduce(reduceSchedules, [])

    const EventDisplay = ({ event, ...props }) => {
        return (
            <div {...props}>
                {event.type === "RecurringShiftPattern" ? <FontAwesomeIcon icon={faRecycle} size="sm" /> : <FontAwesomeIcon icon={faClock} size="sm" />} {event.title}<br />
                <span style={{ fontSize: '0.9em', paddingTop: '0.25rem', display: 'block' }}><FontAwesomeIcon icon={faCircle} color={getColor(event.email)} /> {event.user}</span>
                <P2>{event.description}</P2>
            </div>
        )
    }

    return (
        <Modal
            show={show}
            onClose={onClose}
        >
            <Container>
                <Header>
                    <H1>Shift Schedule</H1>
                    <ButtonBase onClick={onClose}><FontAwesomeIcon icon={faTimesCircle} size={'2x'} /></ButtonBase>
                </Header>
                <Row style={{ minHeight: 'calc(100vh - 15rem)', maxHeight: 'calc(100vh - 25rem)' }}>
                    {
                        schedules.length > 0 && !loading &&
                        <Calendar
                            selectable
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ width: '100%' }}
                            onSelectSlot={onSelectSlot}
                            views={['day', 'week', 'month']}
                            onSelectEvent={onSelectEvent}
                            eventPropGetter={eventPropGetter}
                            defaultView="week"
                            components={{
                                event: EventDisplay
                            }}
                        />
                    }
                    {
                        loading && <Empty><P>Loading...</P></Empty>
                    }

                    {
                        !schedules.length && !loading &&
                        <Empty>
                            <P>No shift schedules for this user, please create one here.</P>
                            <RedButton onClick={() => setShowNewSchedule(true)}>Add Schedule</RedButton>
                        </Empty>
                    }
                </Row>
                {eventDetails && <ModalScheduleEventDetails show onClose={() => setShowEventDetails(false)} slotDetails={eventDetails} />}
                {newSchedule && <ModalAddSchedule onClose={() => setShowNewSchedule(false)} />}
            </Container>
        </Modal>
    )
}

export default ModalShiftSchedule