import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../common/slices/auth';
import { acceptTerms, selectUser } from '../common/slices/user';
import { TERMS_VERSION } from '../common/constants';
import theme from '../common/theme';
import { BlueButton } from './Buttons';
import { Footer } from './Layout';
import Row from './Row';
import FormCheckbox from "./FormCheckbox";
import { useForm } from "react-hook-form";
import { H1, H3, P } from "./Typography";
import styled from "styled-components";
import Divider from "./Divider";
import moment from "moment";
import logoImg from '../assets/logo.svg';


const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    margin-bottom: auto;
    margin: 0 auto;
    max-width: 900px;
    width: 100%;
`

const Terms = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: rgba(16, 18, 36, 1);

    p, li {
        font-size: 0.8rem;
    }

    ol {
        margin-bottom: 1rem;
    }
`

const Heading = styled(H3)`
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
`

const ViewHeader = styled.div`
    border-bottom: 1px solid ${theme.palette.border};
    padding: 1rem;
`

const Logo = styled.img`
    height: 2.5rem;
    border: none;
`

export const TermsOfUse = () => (
    <Terms>
        <iframe src={'https://besafeavatars170609-pre.s3.ca-central-1.amazonaws.com/public/terms-of-use.html'} style={{ flex: 1, border: 'none' }} />       
    </Terms>
)

const ScreenAcceptTerms = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const user = useSelector(selectUser);

    const onCancel = async () => {
        await dispatch(logout());
        nav('/')
    }

    const onAccept = async () => {
        await dispatch(acceptTerms(user.email, TERMS_VERSION))
        nav('/')
    }

    const {
        control,
        formState: { errors, isValid, isSubmitting },
        handleSubmit
    } = useForm();

    const fieldProps = { control, errors, rules: { required: true } };

    return (
        <>
            <ViewHeader>
                <Logo src={logoImg} />
            </ViewHeader>
            <Container>
                <H1>Terms of Use</H1>
                <TermsOfUse user={user} />
                <Row style={{ flex: 0 }}>
                    <FormCheckbox name="accepted" {...fieldProps} placeholder="I have read and accept the terms of use" />
                </Row>
                <Divider />
                <Row style={{ flex: 0 }}>
                    <BlueButton onClick={handleSubmit(onAccept)} disabled={!isValid || isSubmitting}>Accept Terms</BlueButton>
                    <BlueButton onClick={handleSubmit(onCancel)} disabled={isSubmitting}>Cancel</BlueButton>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default ScreenAcceptTerms;
