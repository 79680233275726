const onShiftStatuses = [
    "JOURNEY_PANIC",
    "JOURNEY_ACCIDENT",
    "JOURNEY_NOT_RESPONDING",
    "SHIFT_NOT_RESPONDING",
    "SHIFT_ACCIDENT",
    "SHIFT_PANIC",
]


export default (status) => {
    return onShiftStatuses.indexOf(status) > -1;
}