import { useForm } from "react-hook-form"
import FormCheckbox from "./FormCheckbox"
import styled from "styled-components"
import Divider from "./Divider"
import { BlueButton } from "./Buttons"
import { H1, P } from "./Typography"
import { useNavigate } from "react-router-dom"
import { Container, Form } from "./SelfRegistrationLayout"
import FormTextInput from "./FormTextInput"
import Row from "./Row"
import FormPhoneNumber from "./FormPhoneNumber"
import FormTextArea from "./FormTextArea"
import FormERP from "./FormERP"
import { SRERPArea } from "./SelfRegistrationComponents"
import { useDispatch, useSelector } from "react-redux"
import { selectStatus, updateStatus } from "../common/slices/registration"




export default () => {
    const nav = useNavigate();
    const dispatch = useDispatch();
    const status = useSelector(selectStatus);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting }
    } = useForm({
        values: status?.erpGroups?.groups
    })

    const fieldProps = { control, errors, rules: { required: true, minLength: 1 } }

    const onNext = (values) => {
        dispatch(updateStatus({ step: "erpGroups", status: { done: true, groups: values } }))

        nav("/auth/registration/workers")
    }

    return (
        <Container>
            <div>
                <H1>Emergency Response Plan (ERP) Groups</H1>
                <P>Emergency response plan groups allow you to share emergency contacts and operational processes amongst workers.  Every worker must belong to an ERP Group.</P>
            </div>
            <Form style={{ gap: '1rem', maxWidth: 'none' }}>
                <SRERPArea {...fieldProps} />
            </Form>
            <Divider style={{ marginTop: 'auto' }} />
            <BlueButton disabled={!isValid || isSubmitting} onClick={handleSubmit(onNext)}>Save & Next</BlueButton>
        </Container>
    )
}
