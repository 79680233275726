import { API } from "aws-amplify";
import { updateWholeCompany } from "../graphql/mutations";

export default async (updatedCompany) => {
    try {
        let { data } = await API.graphql({
            query: updateWholeCompany,
            variables: {
                input: updatedCompany,
            },
        });

        return data.updateWholeCompany;
    } catch (ex) {
        console.error("Failed to update company", ex);
    }
};
