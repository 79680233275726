import theme from "../common/theme"
import Badge from "./Badge"

const roleColors = {
    OPERATOR: theme.palette.red,
    WORKER: theme.palette.lightBlue,
    SUPERVISOR: theme.palette.yellow,
}

const roleName = {
    SUPERVISOR: "Supervisor",
    OPERATOR: "Operator",
    WORKER: "Worker",
}

export default ({ role, ...props }) => <Badge color={roleColors[role]} {...props}>{roleName[role]}</Badge>