import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { orderBy } from 'lodash'
import moment from "moment"
import { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { useTable } from 'react-table'
import styled from "styled-components"
import getNextCheckIn from "../common/service/getNextCheckIn"
import { selectCompanyCheckIns, setActiveCheckIn } from "../common/slices/checkIn"
import { selectUser } from '../common/slices/user'
import theme from "../common/theme"
import isNotification from "../common/utils/isNotification"
import isOnJourney from "../common/utils/isOnJourney"
import isOnShift from "../common/utils/isOnShift"
import { setModalState } from "../slices/app"
import Role from "./Role"
import { Status, StatusLastUpdate } from "./Status"
import { FooterButton, Table, TableData, TableFooter, TableHeader, TableRow } from './Table'
import { TabContent } from "./Tabs"
import { P } from "./Typography"

const RowSubText = styled.span`
    display: block;
    font-size: 0.65rem;
`

const TableInfo = styled(P)`
    padding: 0 0 1rem 1rem;
`

const userColumns = [
    { Header: "Status", accessor: "status" },
    { Header: "Location Updated", accessor: "locationUpdated" },
    { Header: "Name", accessor: "name" },
    // { Header: "Company", accessor: "company" },
    { Header: "Role", accessor: "role" },
    { Header: "Shift Start", accessor: "shiftStart" },
    { Header: "Last Check-In", accessor: "manualTimestamp" },
    { Header: "Next Check-In", accessor: "nextCheckin" },
    { Header: "Shift Duration", accessor: "timeOnShift" },
]


const TableOnline = () => {
    const user = useSelector(selectUser);
    const checkIns = useSelector(selectCompanyCheckIns);
    const dispatch = useDispatch();
    const nav = useNavigate();
    const userList = orderBy(checkIns.filter(u => isOnShift(u.status) || isOnJourney(u.status)), 'user.shiftStart');

    const onRowClick = (row) => {
        dispatch(setActiveCheckIn(row.original.userId));
        dispatch(setModalState({ modalName: 'USER_DETAILS', show: true }));
        nav('/');
    }

    const userTable = useTable({
        columns: userColumns, data: userList
    })

    return (
        <TabContent tabId={0}>
            <Table {...userTable.getTableProps()}>
                <thead>
                    {userTable.headerGroups.map(hg => (
                        <tr {...hg.getHeaderGroupProps()}>
                            {hg.headers.map(column => (
                                <TableHeader {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </TableHeader>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...userTable.getTableBodyProps()}>
                    {
                        userTable.rows.map(row => {
                            userTable.prepareRow(row)
                            return (
                                <TableRow {...row.getRowProps()} onClick={() => onRowClick(row)}
                                    style={{
                                        borderLeft: isNotification(row.original.status) ? `2px solid ${theme.palette.red}` : 'none'
                                    }}
                                >
                                    <TableData><Status status={row.original.user.status} style={{ background: 'none', padding: 0, fontWeight: 400 }} /></TableData>
                                    <TableData><StatusLastUpdate checkIn={row.original} style={{ background: 'none', padding: 0, color: 'white' }} title="" /></TableData>
                                    <TableData>{row.original.user.firstName} {row.original.user.lastName}</TableData>
                                    {/* <TableData>{row.original.company.name}</TableData> */}

                                    <TableData><Role role={row.original.user.role} /></TableData>
                                    <TableData>{moment(row.original.user.shiftStart).format("h:mm a")}</TableData>
                                    <TableData>{moment(row.original.user.manualTimestamp).format("h:mm a")} - {moment(row.original.user.manualTimestamp).fromNow()}</TableData>
                                    <TableData>{getNextCheckIn(row.original.user).format("h:mm a")} - {getNextCheckIn(row.original.user).fromNow()}</TableData>
                                    <TableData>{moment.duration(moment().diff(moment(row.original.user.shiftStart))).format("*h:mm [hours]")}</TableData>
                                </TableRow>
                            )
                        })
                    }
                </tbody>
            </Table>
            {
                !userList.length && <TableInfo>There are no workers on shift</TableInfo>
            }
            <TableFooter>
                <FooterButton onClick={() => { }}><FontAwesomeIcon icon={faChevronLeft} /></FooterButton>
                <FooterButton onClick={() => { }}><FontAwesomeIcon icon={faChevronRight} /></FooterButton>
            </TableFooter>
        </TabContent>
    )
}

export default TableOnline