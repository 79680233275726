import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';
import { H3 } from './Typography';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const UncontrolledFormPhoneNumber = forwardRef(({ input, label, ...props }, ref) => (
    <Container>
        <H3 noMargin>{label}</H3>
        <PhoneInput
            ref={ref}
            style={{
                outline: 'none',
            }}
            {...input}
            defaultCountry='CA'
            numberInputProps={{
                style: {
                    border: '1px solid rgb(204, 204, 204)',
                    padding: '0.5rem',
                    outline: 'none',
                }
            }}
            {...props}
        />
    </Container>
))

export default ({ name, control, rules, ...rest }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                ...rules,
            }}
            render={({ field: { ...fieldProps } }) => (
                <UncontrolledFormPhoneNumber {...fieldProps} {...rest} />
            )}
        />
    );
};