import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { BlueButton } from "./Buttons"
import Divider from "./Divider"
import { Container, Form } from "./SelfRegistrationLayout"
import { H1, H2, H3, P, P2 } from "./Typography"
import { AddressElement, Elements, ElementsConsumer, LinkAuthenticationElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import Row from "./Row"
import ReactSlider from 'react-slider'
import { cloneElement, useEffect, useState } from "react"
import { API } from "aws-amplify"
import { setupPayment } from "../common/graphql/mutations"
import FormTextInput, { UncontrolledFormTextInput } from "./FormTextInput"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { completeRegistration, selectStatus, updateStatus } from "../common/slices/registration"
import theme from "../common/theme"


const stripePromise = loadStripe('pk_live_51PtFnDEN9jOXuKwIQc2FLn101icvseLQMHlzfqYUSTJpPTmT6Qchxdba8fVvO0w9KuWFoN9oY58SI9iKfuFME9hR00VDR7u0qG');

const FormHeader = styled(H2)`
    color: white;
    margin: 0;
`

const DiscountCode = styled(UncontrolledFormTextInput)`
    background: rgba(16, 18, 36, 1);
    border: none;
    padding: 1rem;
    color: white;
`

const calcPrice = (count) => {
    if (!count) {
        return 1;
    }
    if (count <= 30) {
        return count * 2999;
    }

    let first30 = (30 * 2999)
    if (count <= 50) {
        return first30 + ((count % 30) * 2499)
    }

    let next20 = (20 * 2499)
    return first30 + next20 + ((count - 50) * 1999)
}

const formatter = new Intl.NumberFormat('en', { style: 'currency', currency: 'cad' });

const appearance = {
    variables: {
        colorPrimary: 'white',
        colorText: 'white',
        colorBackground: '#101123',
        borderRadius: 0,
    }
}

export const StripeWrapper = ({ children }) => {
    const [coupon, setCoupon] = useState(null);
    const [debouncedCoupon, setDebouncedCoupon] = useState("");

    const status = useSelector(selectStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedCoupon(coupon);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [coupon, 500]);

    useEffect(() => {
        (async () => {
            const { data } = await API.graphql({
                query: setupPayment,
                authMode: "API_KEY",
                variables: {
                    input: {
                        email: status.accountOwner?.owner?.email,
                        name: status.company?.company?.name,
                        address: {
                            city: status.company?.company?.city,
                            state: status.company?.company?.province,
                            postalCode: status.company?.company?.postalCode,
                            country: status.company?.company?.country?.value,
                            line1: status.company?.company?.address,
                        },
                        trialDays: status.termsOfService.trialLength,
                        coupon,
                    }
                }
            })
            dispatch(updateStatus({ step: "payment", status: { state: data.setupPayment } }))
        })();
    }, [debouncedCoupon])

    if (!status?.payment?.state) { return <Container><P>Loading....</P></Container> }
    return (<Elements stripe={stripePromise} options={{ clientSecret: status?.payment?.state?.clientSecret, appearance }}>{children({ coupon, setCoupon, intent: status?.payment?.state })}</Elements>)
}


export default () => {
    const nav = useNavigate();
    const status = useSelector(selectStatus);
    const [count, setCount] = useState(status.workers?.workers?.workers?.length || 10);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const price = calcPrice(count);

    return (
        <StripeWrapper>
            {({ coupon, setCoupon, intent }) => (

                <Container>
                    <div>
                        <H1>Payment</H1>
                        <P>Be Safe automatically calculates the number of licenses you use based on the number of active users per month.  Pricing is then determined by the tier that best fits the usage.</P>
                        <H3 noMargin>Pricing Structure</H3>
                        <ul style={{ margin: 0 }}>
                            <li>First 30 Licenses: $29.99 per license</li>
                            <li>Next 20 Licenses: $24.99 per license</li>
                            <li>Remaining Licenses: $19.99 per license</li>
                        </ul>
                    </div>
                    <Form style={{ maxWidth: '1200px' }}>
                        <Row style={{ alignItems: 'flex-start' }}>
                            <div style={{ flex: 2.5, color: 'white', gap: '1rem', display: 'flex', flexDirection: 'column' }}>
                                <div style={{ padding: '1rem', background: 'rgba(41, 44, 84, 0.5)' }}>
                                    <FormHeader>Link</FormHeader>
                                    <P>Complete your purchase quickly and securely with Stripe's fast checkout service, <a href="https://link.com/" target="_blank">Link</a>.</P>
                                    <LinkAuthenticationElement options={{ defaultValues: { email: "mike@rsc.dev" } }} />
                                </div>
                                <FormHeader>Payment</FormHeader>
                                <P noMargin>Your card will be charged automatically each month, and the account owner will receive an invoice via email detailing your accounts usage.</P>
                                <PaymentElement />
                            </div>
                            <div style={{ flex: 1, padding: '1rem', background: 'rgba(41, 44, 84, 0.5)' }}>
                                <P>Total Cost Summary</P>
                                <P2>Drag the slider below to estimate your monthly cost as the number of active monthly users on your account increases.</P2>
                                <ReactSlider
                                    max={200}
                                    min={status.workers?.workers?.workers?.length}
                                    value={count}
                                    onChange={(v) => setCount(v)}
                                    className="horizontal-slider"
                                    thumbClassName="example-thumb"
                                    trackClassName="example-track"
                                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                />
                                <Divider />
                                <P>{count} Licenses</P>
                                {
                                    count <= 30 &&
                                    <P2>{formatter.format(29.99)} x {count} Licenses</P2>
                                }
                                {
                                    count > 30 && count <= 50 &&
                                    <>
                                        <P2>{formatter.format(29.99)} x 30 Licenses</P2>
                                        <P2>{formatter.format(24.99)} x {count - 30} Licenses</P2>
                                    </>
                                }
                                {
                                    count > 50 &&
                                    <>
                                        <P2>{formatter.format(29.99)} x 30 Licenses</P2>
                                        <P2>{formatter.format(24.99)} x 20 Licenses</P2>
                                        <P2>{formatter.format(19.99)} x {count - 50} Licenses</P2>
                                    </>
                                }
                                <Divider />
                                <Row>
                                    <P style={{ flex: 1 }}>Subtotal</P>
                                    {formatter.format(price / 100)}
                                </Row>
                                <Row>
                                    <P style={{ flex: 1 }}>Tax</P>
                                    {formatter.format((price / 100) * 0.05)}
                                </Row>
                                <Row>
                                    <P style={{ flex: 1 }}>Total</P>
                                    {formatter.format((price / 100) * 1.05)}
                                </Row>
                                <div>
                                    <P>Discount Code</P>
                                    <DiscountCode onChange={(e) => setCoupon(e.target.value)} value={coupon} placeholder="Enter discount code" />
                                    {
                                        intent?.discountApplied &&
                                        <Row>
                                            <FontAwesomeIcon icon={faCircleCheck} color='white' />
                                            <P2>Discount Applied</P2>
                                        </Row>
                                    }
                                </div>
                            </div>
                        </Row>
                    </Form>
                    <Divider style={{ marginTop: 'auto' }} />
                    {errorMessage !== "" && <P style={{ color: theme.palette.red }}>{errorMessage}</P>}
                    <ElementsConsumer>
                        {({ stripe, elements }) => (
                            <BlueButton disabled={loading} onClick={async () => {
                                setLoading(true);
                                const resp = await stripe.confirmSetup({
                                    elements,
                                    redirect: 'if_required',
                                    confirmParams: {
                                        return_url: "http://localhost:3000/payment-redirect"
                                    }
                                })
                                if (resp.error) { setErrorMessage(resp.error.message); }
                                else {
                                    setErrorMessage("");
                                    const { success } = await dispatch(completeRegistration());
                                    if (success) {
                                        nav('/thank-you');
                                    }
                                }
                                setLoading(false);
                            }}>Complete Registration</BlueButton>
                        )}
                    </ElementsConsumer>
                </Container >
            )}
        </StripeWrapper>
    )
}
