import { API } from "aws-amplify"
import { validateEmail } from '../graphql/queries'
import { EMAIL_PATTERN } from "../constants"

export default async (email) => {
    const match = email.match(EMAIL_PATTERN);

    if (!match) {
        return "Invalid email address"
    }

    const { data: { validateEmail: result } } = await API.graphql({
        query: validateEmail,
        authMode: "API_KEY",
        variables: {
            input: { email }
        }
    })

    if (result.success) {
        return true;
    } else {
        return result.message;
    }
}