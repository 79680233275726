import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { BlueButton } from "./Buttons"
import Divider from "./Divider"
import { Container, Form } from "./SelfRegistrationLayout"
import { SRTextArea, SRTextInput, FormSelectCountry, FormSelectTimezone } from "./SelfRegistrationComponents"
import { H1, H2, P } from "./Typography"
import { selectStatus, updateStatus } from "../common/slices/registration"
import { useDispatch, useSelector } from "react-redux"
import { EMAIL_PATTERN } from "../common/constants"
import Row from "./Row"


export default () => {
    const nav = useNavigate();
    const dispatch = useDispatch();
    const status = useSelector(selectStatus);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting }
    } = useForm({
        values: status?.company?.company
    })

    const fieldProps = { control, errors, rules: { required: true } }

    const onNext = (values) => {
        dispatch(updateStatus({ step: "company", status: { done: true, company: values } }))
        nav("/auth/registration/erp-groups")
    }

    return (
        <Container>
            <div>
                <H1>Company</H1>
                <P>Tell us about your company including the companies preferred billing address and reporting timezone.  We will use this information to setup your monthly billing profile.</P>
            </div>
            <Form>
                <SRTextInput name="name" label="Company Name" placeholder="Enter company name" {...fieldProps} />
                <H2>Billing Address</H2>
                <SRTextInput name="address" label="Address" placeholder="Enter billing address" {...fieldProps} />
                <Row>
                    <SRTextInput name="city" label="City" placeholder="Enter billing city" {...fieldProps} />
                    <SRTextInput name="postalCode" label="Postal Code" placeholder="Enter billing postal code" {...fieldProps} />
                </Row>
                <Row>
                    <SRTextInput name="province" label="Province" placeholder="Enter billing province" {...fieldProps} />
                    <FormSelectCountry name="country" label="Country" placeholder="Enter billing country" {...fieldProps} />
                </Row>

                <H2>Preferred Timezone</H2>
                <FormSelectTimezone {...fieldProps} />

                <H2>Contact Notes</H2>
                <P noMargin>Provide any relevant contact notes for your company.  Use the field below to customize your companies contact preferences (you can adjust this after registration is complete).</P>
                <SRTextArea rows="4" name="contactNotes" label="Emergency Contact Details" placeholder="Enter any specific notes that should appear for our operators when an alert arises for your company.  For example, you may want our contact center to only contact certain emergency contacts before 10PM, or perhaps only call certain people on the weekend." {...fieldProps} rules={{ required: false }} />

            </Form>
            <Divider style={{ marginTop: 'auto' }} />
            <BlueButton disabled={!isValid || isSubmitting} onClick={handleSubmit(onNext)}>Save & Next</BlueButton>
        </Container>
    )
}
