import { useEffect } from "react"
import styled from "styled-components"
import { H1, H2, P } from "./Typography"
import logoImg from '../assets/logo.svg';
import { useNavigate } from "react-router-dom";

const Content = styled.div`
    padding: 1rem 5rem;
    padding-bottom: 5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
`
const Logo = styled.img`
    height: 2.5rem;
    padding: 0;
    margin: 1rem 0 2rem 0;
    align-self: flex-start;
    cursor: pointer;
`

const UL = styled.ul`
    margin: 0;
`

const LI = styled.li`
`

export default () => {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'Be Safe - Work Alone - Privacy Policy'
    }, [])

    return (
        <Content>
            <Logo src={logoImg} onClick={() => navigate('/')} />
            <H1>Privacy Policy</H1>
            <P>This privacy policy document contains types of information that is collected and recorded by Be Safe - Work Alone and how we use it.</P>
            <P>If you have additional questions or require more information about our privacy policy, do not hesitate to contact us at mike@rsc.dev.</P>
            <P>This privacy policy applies to both our mobile application and our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect and the Be Safe - Work Alone app. This policy is not applicable to any information collected offline or via channels other than the before mentioned.</P>
            <H2>Consent</H2>
            <P>By using our website or mobile app, you hereby consent to our privacy policy and agree to its terms.</P>
            <H2>Information We Collect</H2>
            <P>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</P>
            <P>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</P>
            <P>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</P>
            <H2>How We Use Your Information</H2>
            <P>We use the information we collect in various ways, including to:</P>
            <UL>
                <LI>Provide, operate, and maintain our website</LI>
                <LI>Improve, personalize, and expand our website</LI>
                <LI>Understand and analyze how you use our website</LI>
                <LI>Develop new products, services, features, and functionality</LI>
                <LI>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</LI>
                <LI>Send you emails</LI>
                <LI>Find and prevent fraud</LI>
                <LI>Log Files</LI>
            </UL>
            <P>Be Safe - Work Alone follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</P>
            <H2>Location Data</H2>
            <P>The Be Safe - Work Alone app uses your location when you are on-shift or on a journey to let operators know of your last location.  When you go off shift, or complete your journey, your location will no longer be tracked.  In an effort to conserve battery life, the Be Safe - Work Alone app uses background location updates so that the phone screen doesn't need to be on.  Your location data is not shared with anyone outside of the Be Safe - Work Alone app.</P>
            <H2>Cookies and Web Beacons</H2>
            <P>Like any other website, Be Safe - Work Alone uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</P>
            <H2>DoubleClick DART Cookie</H2>
            <P>Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – https://policies.google.com/technologies/ads</P>
            <H2>Children's Information</H2>
            <P>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</P>
            <P>Be Safe - Work Alone does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</P>
            <H2>Questions</H2>
            <P>If you have any questions about this privacy policy, please contact us at info@rsc.dev</P>
        </Content>
    )
}