import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";

export default ({ imgKey, ...props }) => {
    const [uri, setUri] = useState(null);
    useEffect(() => {
        const getUri = async (k) => {
            const resp = await Storage.get(k);
            setUri(resp);
        };

        if (imgKey) {
            getUri(imgKey);
        }
    }, [imgKey]);

    if (!uri) {
        return <></>;
    }
    return <img src={uri} {...props} />;
};
