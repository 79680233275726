import React from "react";
import { BlackButton, BlueButton, ButtonBase, DarkButton } from "./Buttons";
import Modal from "./Modal";
import Row from "./Row";
import { H1, P } from "./Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const ModalConfirm = ({ onClose, title = "Confirm", description, confirmButtonTitle = 'delete', onConfirm }) => {
    return (
        <Modal show size="33%">
            <Container>

                <Row>
                    <H1>{title}</H1>
                    <ButtonBase onClick={onClose} style={{ marginLeft: 'auto' }}><FontAwesomeIcon icon={faTimes} size='16px' color="#000" /></ButtonBase>
                </Row>

                <P dangerouslySetInnerHTML={{ __html: description }} />
                <DarkButton onClick={onConfirm}>{confirmButtonTitle}</DarkButton>
            </Container>
        </Modal>
    );
};

export default ModalConfirm;
