import styled from "styled-components"

export default styled.span`
    background-color: ${props => props.color};
    border-color: ${props => props.color};
    border-width: 1px;
    border-radius: 1rem;
    padding: 0.3rem 1rem;
    font-size: 0.8rem;
    display: inline-block;
`
