const theme = {
    palette: {
        background: "#171931",
        typography: "#fff",
        border: "rgba(41, 44, 84, 1)",
        dark: '#040408',
        green: "#42A776",
        blue: "#171931",
        darkBlue: 'rgba(23, 25, 49, 1)',
        lightBlue: "#4385F3",
        red: "#b53434",
        yellow: "#f2b401"
    }
}

export default theme;