import { faChevronDown, faChevronUp, faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { clearSearchFilter, selectActiveCheckIn, selectSearchFilter, setActiveCheckIn, setSearchFilter } from '../common/slices/checkIn';
import theme from '../common/theme';
import { selectModalState, setModalState } from '../slices/app';
import MapCheckIn from './MapCheckIn';
import MapControls from './MapControls';
import MapInfo from './MapInfo';
import ModalUserDetails from './ModalUserDetails';
import { UncontrolledFormTextInput } from './FormTextInput';

const MapHeader = styled.div`
    display: flex;
    flex-direction: row;
`

const MenuContainer = styled.div`
    flex: 1;
    padding: 1rem;
    border: 1px solid rgba(41, 44, 84, 1);
`

const DropDownButton = styled.div`
    color: white;
    cursor: pointer;
    font-weight: 700;
    display: flex;
`

const DropDownContent = styled.div`
    cursor: pointer;
    position: fixed;
    background: ${theme.palette.darkBlue};
    padding: 1rem;
    margin-top: 1rem;
    z-index: 3;
    min-width: 350px;
    gap: 1rem;
    display: flex;
    flex-direction: column;
`

const StatsDropdown = styled((props) => {
    const [opened, setOpened] = useState(false);

    return (
        <MenuContainer {...props} onClick={() => setOpened(!opened)}>
            <DropDownButton>Stats <FontAwesomeIcon icon={opened ? faChevronDown : faChevronUp} style={{ marginLeft: 'auto' }} /></DropDownButton>
            {
                opened &&
                <DropDownContent>
                    <MapInfo />
                </DropDownContent>
            }
        </MenuContainer>
    )
}
)``

const FilterDropdown = styled((props) => {
    const [opened, setOpened] = useState(false);

    return (
        <MenuContainer {...props} onClick={(e) => setOpened(!opened)}>
            <DropDownButton>Filter <FontAwesomeIcon icon={opened ? faChevronDown : faChevronUp} style={{ marginLeft: 'auto' }} /></DropDownButton>
            {
                opened &&
                <DropDownContent>
                    <MapControls />
                </DropDownContent>
            }
        </MenuContainer>
    )
}
)``

const SearchInput = styled(UncontrolledFormTextInput)`
    background: transparent;
    border: none;
    color: white;
    padding: 0;

    &::placeholder {
        // color: ${theme.palette.border}
    }
`

const UserSearch = ({ onSearch }) => {
    const searchFilter = useSelector(selectSearchFilter);
    return (
        <MenuContainer>
            <SearchInput
                onChange={(e) => onSearch(e.target.value)}
                type="search"
                value={searchFilter}
                placeholder="Search name, email, phone number"
            />
        </MenuContainer>
    )
}

const ScreenHome = () => {
    const dispatch = useDispatch();
    const activeCheckIn = useSelector(selectActiveCheckIn);
    const showModal = useSelector(selectModalState("USER_DETAILS"))
    const mapRef = createRef();

    const onCheckInClick = (checkIn) => {
        dispatch(setActiveCheckIn(checkIn.userId));
        dispatch(setModalState({ modalName: 'USER_DETAILS', show: true }))
    }

    const zoomIn = () => {
        const current = mapRef.current?.state?.map?.getZoom()
        mapRef.current?.state?.map?.setZoom(current + 1);
    }

    const zoomOut = () => {
        const current = mapRef.current?.state?.map?.getZoom()
        mapRef.current?.state?.map?.setZoom(current - 1);
    }

    const onSearch = (value) => {
        value !== "" ?
            dispatch(setSearchFilter(value)) :
            dispatch(clearSearchFilter());
    }


    return (
        <>
            <MapHeader>
                <StatsDropdown />
                <FilterDropdown />
                {/* <UserSearch style={{ flex: 1, }} onSearch={onSearch} /> */}
                <MenuContainer style={{ flex: 0, cursor: 'pointer' }} onClick={zoomIn}><FontAwesomeIcon icon={faSearchPlus} /></MenuContainer>
                <MenuContainer style={{ flex: 0, cursor: 'pointer' }} onClick={zoomOut}><FontAwesomeIcon icon={faSearchMinus} /></MenuContainer>
            </MapHeader>
            <MapCheckIn
                ref={mapRef}
                onClick={onCheckInClick}
                center={activeCheckIn?.location}
            />
            <ModalUserDetails show={showModal} onClose={() => dispatch(setModalState({ modalName: 'USER_DETAILS', show: false }))} />
        </>
    )
}

export default ScreenHome;
