import S3Image from "./S3Image";
import styled from "styled-components";
import theme from "../common/theme";

export default styled(({ avatar, ...props }) => {
    const key = avatar || 'default.png';
    return <S3Image imgKey={key} {...props} />
})`
    width: 5rem;
    height: 5rem;
    border: 1px solid ${theme.palette.border};
    border-radius: 3rem;
`