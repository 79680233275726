import ColorHash from 'color-hash';
let colorMap = {};

const ch = new ColorHash({
    lightness: [0.4, 0.6],
    saturation: [0.4, 0.6],
    hue: [40, 340]
})


export const getColor = (groupId) => {
    let color = colorMap[groupId];
    if (!color) {
        colorMap[groupId] = ch.hex(JSON.stringify({ groupId }));
    }

    return colorMap[groupId];
}