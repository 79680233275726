import styled from "styled-components";
import theme from "../common/theme";

export const ButtonBase = styled(({ children, ...props }) => <button {...props} type="button">{children}</button>)`
    background-color: transparent;
    color: ${theme.palette.dark};
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 0;
    opacity: ${props => props.disabled ? '0.12' : '1'};

    &:hover {        
        opacity: ${props => props.disabled ? '0.12' : '0.7'};
    }    
`
export const RedButton = styled(ButtonBase)`
        background-color: ${theme.palette.red};
        color: white;
        padding: 0.5rem 3rem !important;
    `

export const GreenButton = styled(ButtonBase)`
        background-color: ${theme.palette.green};
        color: white;
        padding: 0.5rem 1rem !important;
    `

export const BlueButton = styled(ButtonBase)`
        background-color: ${theme.palette.lightBlue};
        color: white;
        padding: 0.5rem 1rem !important;
    `

export const DarkButton = styled(ButtonBase)`
        background-color: ${theme.palette.darkBlue};
        color: white;
        padding: 0.5rem 1rem !important;
    `

export const AdminButton = styled(ButtonBase)`
    color: white;
    padding: 0.5rem 1rem;
    font-weight: 700;
    background: ${theme.palette.lightBlue};
`

export const OutlineButton = styled(ButtonBase)`
    color: white;
    border: 1px solid #fff;
    padding: 0.5rem 1rem;
    font-weight: 700;
    flex: 1;
`

export const NavButton = styled(ButtonBase)`
    color: white;
    padding: 0.5 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    text-wrap: nowrap;
    gap: 1rem;
    flex: 0;
`