import { createSelector, createSlice } from "@reduxjs/toolkit";
import service from "../service";
import { reset } from "../common/slices/globalActions";

const initialState = {
    report: {}
};

const reportSlice = createSlice({
    name: "report",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(reset, (state) => {
            return initialState;
        });
    },
    reducers: {    
        setShiftReports: (state, action) => {
            state.report = action.payload;
        }    
    },
});

export const { setShiftReports } = reportSlice.actions;
export default reportSlice.reducer;

export const selectState = (state) => state.report;
export const selectReport = createSelector(selectState, (state) => state.report);
export const selectReportTable = (state) => {
    const report = selectReport(state);
    console.log(report);
    const reportRows = report.reports?.[0]?.report.split('\n') || [];
    return reportRows.map(r => r.split(","));
}

export const getShiftReports = (startDate, endDate, companyId = "") => async (dispatch, getState) => {
    const summary = await service.getShiftReports({
        startDate,
        endDate,
        companyId,
    })
    dispatch(setShiftReports(summary));
};
