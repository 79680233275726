import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import friendlyStatus from "../common/utils/friendlyStatus"
import S3ImageCheckIn from "./S3ImageCheckIn"
import { P } from "./Typography"
import moment from "moment"
import isNotification from "../common/utils/isNotification"
import theme from "../common/theme"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import getUpdateDelay from "../service/getUpdateDelay"

export const Status = ({ status, style }) => (
    <span style={{
        background: '#000',
        color: 'white',
        display: 'flex',
        flex: 0,
        padding: '0.5rem 1rem',
        textWrap: 'nowrap',
        gap: '0.5rem',
        fontWeight: 700,
        alignItems: 'center',
        ...style,
    }}><S3ImageCheckIn status={status} /> {friendlyStatus(status)}</span>
)
export const StatusLastUpdate = ({ checkIn, style, title = "Location Updated" }) => (
    <span style={{
        background: isNotification(checkIn?.status) ? theme.palette.red : 'white',
        color: isNotification(checkIn?.status) ? 'white' : 'black',
        display: 'flex',
        flex: 1,
        padding: '0.5rem 1rem',
        alignItems: 'center',
        gap: '0.5rem',
        ...style,
    }}>
        <FontAwesomeIcon icon={faCircle} size="sm" color={checkIn.status !== "OFF" ? moment(checkIn.timestamp).add(getUpdateDelay(checkIn), 's').isAfter(moment()) ? theme.palette.green : theme.palette.yellow : theme.palette.border} />
        <P noMargin style={{ fontSize: 'inherit' }}>
            {title && <><b>{title}</b>&nbsp;-&nbsp;</>}
            {moment(checkIn.timestamp).fromNow()}
        </P>
    </span>
)