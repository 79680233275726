import { faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from 'react';
import { useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectStatus } from "../common/slices/registration";
import theme from "../common/theme";
import { ButtonBase, OutlineButton } from "./Buttons";
import Divider from "./Divider";
import FormPhoneNumber from "./FormPhoneNumber";
import FormSelectInput from './FormSelectInput';
import FormTextArea from "./FormTextArea";
import FormTextInput from "./FormTextInput";
import Row from "./Row";
import { H2, P } from "./Typography";
import { v4 } from 'uuid'
import validateEmail from "../common/service/validateEmail";
import { ErrorMessage } from "@hookform/error-message";

export const SREmailInput = ({ ...props }) => <SRTextInput {...props} rules={{ ...props.rules, validate: validateEmail }} />

export const SRTextInput = styled(FormTextInput)`
    background: rgba(16, 18, 36, 1);
    border: none;
    padding: 1rem;
    color: white;
`

export const SRTextArea = styled(FormTextArea)`
    background: rgba(16, 18, 36, 1);
    border: none;
    padding: 1rem;
    color: white;
    
    &::placeholder {
        color: rgba(129, 129, 129, 1);
        }
        `

export const SRPhoneNumber = ({ ...props }) => <FormPhoneNumber {...props} numberInputProps={{
    style: {
        background: 'rgba(16, 18, 36, 1)',
        border: 'none',
        padding: '1rem',
        color: 'white'
    }
}} />


export const SRERPArea = ({ name = "erpAreas.items", control, errors, rules }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name,
        keyName: "keyId",
        rules
    })

    const fieldProps = { control, errors }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {fields.map((item, index) => {
                const onDelete = () => {
                    remove(index);
                }
                return (
                    <div key={item.keyId} style={{ display: 'flex', gap: '1rem', flexDirection: 'column', background: 'rgba(41, 44, 84, 1)', padding: '1rem' }}>
                        <Row style={{ alignItems: 'flex-end' }}>
                            <SRTextInput
                                {...fieldProps}
                                rules={{ required: true }}
                                placeholder="Enter a group name"
                                label="Group Name"
                                name={`${name}.${index}.name`}
                            />
                            <ButtonBase onClick={onDelete} style={{ flex: 1, padding: '1rem 0', color: 'white', textAlign: 'left' }}><FontAwesomeIcon icon={faTrash} /></ButtonBase>
                        </Row>
                        <SRTextArea {...fieldProps} rows="2" name={`${name}.${index}.contactNotes`} label="Contact Notes" placeholder="Enter any relevant notes you want to appear for our contact center when alerts are triggered for workers within this ERP Area." />
                        <H2 noMargin>Emergency Contacts</H2>
                        <SREmergencyContacts
                            {...fieldProps}
                            // rules={{ required: true }}
                            name={`${name}.${index}.emergencyContacts.items`}
                        />
                    </div>
                )
            })}
            <Divider />
            <OutlineButton onClick={() => append({ id: v4() })}><FontAwesomeIcon icon={faCirclePlus} /> Add ERP Group</OutlineButton>
        </div>
    )
}

export const SREmergencyContacts = ({ name = "emergencyContacts.items", control, errors, rules }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name,
        keyName: 'keyId',
        rules
    },)
    const fieldProps = { control, errors, rules: { required: { value: true, message: "Required" } } }
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {fields.map((item, index) => {
                const onDelete = () => {
                    remove(index);
                }

                return (
                    <Row key={item.keyId} style={{ alignItems: 'flex-end' }}>
                        <SRTextInput
                            {...fieldProps}
                            placeholder="Enter #"
                            label="Priority"
                            name={`${name}.${index}.priority`}
                            containerStyle={{ flex: 0, width: '100px' }}
                        />
                        <SRTextInput
                            {...fieldProps}
                            placeholder="Enter name"
                            label="Name"
                            name={`${name}.${index}.name`}
                            containerStyle={{ flex: 1 }}
                        />
                        <SRPhoneNumber
                            {...fieldProps}
                            placeholder="Enter phone number"
                            label="Phone Number"
                            name={`${name}.${index}.phone`}
                            defaultCountry="CA"
                        />
                        <SRTextInput
                            {...fieldProps}
                            placeholder="Enter email"
                            label="Email"
                            name={`${name}.${index}.email`}
                            containerStyle={{ flex: 1 }}
                            rules={{
                                required: true,
                                pattern: {
                                    value: re,
                                    message: "Invalid email"
                                }
                            }}
                        />
                        <ButtonBase onClick={onDelete} style={{ padding: '1rem 0', color: 'white' }}><FontAwesomeIcon icon={faTrash} /></ButtonBase>
                    </Row>
                )
            })}
            {!fields.length && <P noMargin>This ERP Group does not have any emergency contacts defined yet.  Please create one or more below.</P>}
            <OutlineButton onClick={() => append({ id: v4() })}><FontAwesomeIcon icon={faCirclePlus} /> Add Emergency Contact</OutlineButton>
        </div>
    )
}



export const FormWorkers = ({ name = "workers", control, errors, rules }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name,
        keyName: 'keyId',
        rules,
    },)
    const fieldProps = { control, errors, rules: { required: { value: true, message: "Required" } } }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {fields.map((item, index) => {
                const onDelete = () => {
                    remove(index);
                }

                return (
                    <div key={item.keyId} style={{ background: 'rgba(41, 44, 84, 1)', padding: '1rem' }}>
                        <H2 noMargin style={{ marginBottom: '1rem' }}>Worker {index + 1}</H2>
                        <Row>
                            <div style={{ flex: 1, gap: '1rem', display: 'flex', flexDirection: 'column' }}>
                                <Row style={{ alignItems: 'flex-end' }}>
                                    <SRTextInput
                                        {...fieldProps}
                                        placeholder="Enter first name"
                                        label="First Name"
                                        name={`${name}.${index}.firstName`}
                                        containerStyle={{ flex: 1 }}
                                    />
                                    <SRTextInput
                                        {...fieldProps}
                                        placeholder="Enter last name"
                                        label="Last Name"
                                        name={`${name}.${index}.lastName`}
                                        containerStyle={{ flex: 1 }}
                                    />
                                    <SREmailInput
                                        {...fieldProps}
                                        placeholder="Enter email"
                                        label="Email"
                                        name={`${name}.${index}.email`}
                                        containerStyle={{ flex: 1 }}                                        
                                    />
                                </Row>

                                <Row>
                                    <SRPhoneNumber
                                        {...fieldProps}
                                        placeholder="Enter phone number"
                                        label="Phone Number"
                                        name={`${name}.${index}.phoneNumber`}
                                        defaultCountry="CA"
                                    />
                                    <FormSelectERP name={`${name}.${index}.erpAreaId`} {...fieldProps} containerStyle={{ flex: 2 }} />
                                </Row>
                            </div>
                            <ButtonBase onClick={onDelete} style={{ padding: '0.25rem 0', color: 'white' }}><FontAwesomeIcon icon={faTrash} /></ButtonBase>
                        </Row>
                    </div>
                )
            })}
            {!fields.length && <P>No workers yet.  Please create one below.</P>}
            <OutlineButton onClick={() => append({ id: v4() })}><FontAwesomeIcon icon={faCirclePlus} />  Add Worker</OutlineButton>
        </div>
    )
}


const FormSelectERP = ({ setValue, ...props }) => {
    const status = useSelector(selectStatus);
    const [defaultOptions, setDefaultOptions] = useState(status.erpGroups?.groups?.erpAreas?.items?.map(i => ({ value: i.id, label: i.name })));

    const loadOptions = async () => {
        return Promise.resolve(status.erpGroups?.groups?.erpAreas?.items?.map(i => ({ value: i.id, label: i.name })))
    }

    return (
        <FormSelectInput
            name="erpAreaId"
            placeholder="Select ERP Group"
            label="ERP Group"
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
            {...props}
            singleValueStyle={{ color: 'white' }}
            controlStyle={{ background: 'rgba(16, 18, 36, 1)', border: `1px solid ${theme.palette.border}`, padding: '0.5rem' }}
        />
    )
}

export const FormSelectCountry = ({ setValue, ...props }) => {
    const countries = [
        { value: 'CA', label: 'Canada' },
        { value: 'US', label: 'United States of America' }
    ]
    const [defaultOptions, setDefaultOptions] = useState(countries);

    const loadOptions = async () => {
        return Promise.resolve(countries)
    }

    return (
        <FormSelectInput
            name="country"
            placeholder="Select Country"
            label="Country"
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
            {...props}
            singleValueStyle={{ color: 'white' }}
            controlStyle={{ background: 'rgba(16, 18, 36, 1)', border: `1px solid ${theme.palette.border}`, padding: '0.5rem' }}
        />
    )
}

export const FormSelectTimezone = ({ setValue, ...props }) => {
    const options = [
        { value: -7, label: 'Pacific Daylight Time' },
        { value: -6, label: 'Mountain Daylight Time' },
        { value: -5, label: 'Central Daylight Time' },
        { value: -4, label: 'Eastern Daylight Time' },
        { value: -3, label: 'Atlantic Daylight Time' },
        { value: -2.5, label: 'Newfoundland Daylight Time' },
    ]
    const [defaultOptions, setDefaultOptions] = useState(options);

    const loadOptions = async () => {
        return Promise.resolve(options)
    }

    return (
        <FormSelectInput
            name="utcOffset"
            placeholder="Select Timezone"
            label="Timezone"
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
            {...props}
            singleValueStyle={{ color: 'white' }}
            controlStyle={{ background: 'rgba(16, 18, 36, 1)', border: `1px solid ${theme.palette.border}`, padding: '0.5rem' }}
        />
    )
}

export default FormSelectERP;