import { useEffect, useState } from "react"
import { formatPhoneNumber } from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import { useTable } from 'react-table'
import styled from "styled-components"
import { listUsers, nextUserPage, prevUserPage, selectUser, selectUserList, selectUserPagination, setActiveUser } from '../common/slices/user'
import friendlyProtocol from "../common/utils/friendlyProtocol"
import { AdminButton } from "./Buttons"
import ModalAddUser from "./ModalAddUser"
import Role from "./Role"
import Search from "./Search"
import { FooterButton, Table, TableData, TableFooter, TableHeader, TableRow } from './Table'
import { TabContent } from "./Tabs"
import { ButtonIcon } from "./Typography"
import { faChevronLeft, faChevronRight, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import friendlyStatus from "../common/utils/friendlyStatus"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ScreenHeader = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`

const userColumns = [
    { Header: "Name", accessor: "name" },
    { Header: "Role", accessor: "role" },
    { Header: "Email", accessor: "email" },
    { Header: "Phone Number", accessor: "phoneNumber" },
    { Header: "Protocol", accessor: "checkInProtocol" },
    { Header: "Status", accessor: "status" },
]

const TableUser = () => {
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const dispatch = useDispatch();

    const userList = useSelector(selectUserList);
    const user = useSelector(selectUser);

    useEffect(() => {
        dispatch(listUsers(user.companyId, user.role))
    }, [dispatch, user.companyId, user.role])

    const onRowClick = (row) => {
        dispatch(setActiveUser(row.original.email))
        setShowAddUserModal(true);
    }

    const userData = userList.map(u => ({
        ...u,
        name: `${u.firstName} ${u.lastName}`
    }))

    const userTable = useTable({
        columns: userColumns, data: userData
    })

    return (
        <TabContent tabId={1}>
            <ScreenHeader>
                <Search />
                <AdminButton onClick={() => setShowAddUserModal(true)}><ButtonIcon icon={faPlusCircle} /> Add New User</AdminButton>
            </ScreenHeader>
            <Table {...userTable.getTableProps()}>
                <thead>
                    {userTable.headerGroups.map(hg => (
                        <tr {...hg.getHeaderGroupProps()}>
                            {
                                hg.headers.map(column => (
                                    <TableHeader {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </TableHeader>
                                ))
                            }
                        </tr>
                    ))}
                </thead>
                <tbody {...userTable.getTableBodyProps()}>
                    {
                        userTable.rows.map(row => {
                            userTable.prepareRow(row)
                            return (
                                <TableRow {...row.getRowProps()} onClick={() => onRowClick(row)}>
                                    <TableData>{row.original.name}</TableData>
                                    <TableData><Role role={row.original.role} /></TableData>
                                    <TableData>{row.original.email}</TableData>
                                    <TableData>{formatPhoneNumber(row.original.phoneNumber)}</TableData>
                                    <TableData>{friendlyProtocol(row.original.checkInProtocol)}</TableData>
                                    <TableData>{friendlyStatus(row.original.status)}</TableData>
                                </TableRow>
                            )
                        })
                    }
                </tbody>
            </Table>
            <TableFooter>
                <FooterButton onClick={() => dispatch(prevUserPage(user.companyId, user.role))}><FontAwesomeIcon icon={faChevronLeft} /></FooterButton>
                <FooterButton onClick={() => dispatch(nextUserPage(user.companyId, user.role))}><FontAwesomeIcon icon={faChevronRight} /></FooterButton>
            </TableFooter>
            {showAddUserModal && <ModalAddUser show onClose={() => {
                setShowAddUserModal(false)
                dispatch(setActiveUser(null));
            }} />}
        </TabContent>
    )
}

export default TableUser