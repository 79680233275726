import FormMultiSelect from "./FormMultiSelect";
import FormSelect from "./FormSelect";

const FormSelectShiftPattern = ({ name = "shiftPattern", placeholder = "Select Shift Pattern", label = "Shift Days", ...rest }) => {
    return (
        <FormMultiSelect {...rest} name={name} placeholder={placeholder} label={label} options={[
            { value: '', label: "Select Shift Type" },
            { value: 'MONDAY', label: "Monday" },
            { value: 'TUESDAY', label: "Tuesday" },
            { value: 'WEDNESDAY', label: "Wednesday" },
            { value: 'THURSDAY', label: "Thursday" },
            { value: 'FRIDAY', label: "Friday" },
            { value: 'SATURDAY', label: "Saturday" },
            { value: 'SUNDAY', label: "Sunday" },
        ]} />
    )
}

export default FormSelectShiftPattern;